<div class="otp-page">
  <div class="card otp-container">
    <form [formGroup]="otpForm" (ngSubmit)="onVerifyOTP()">
      <div class="card-body otp-content">
        <div>
          <a routerLink="/home">
            <img class="otp-logo" src="../../../assets/img/parchive-logo.png" alt="Parchive logo">
          </a>
        </div>
        <div class="form-header">
          <h5>Account verification</h5>
        </div>
        <div class="form-group">
          <p class="text-center" for="OTP">Please enter your verification code from SMS/Email</p>
          <input type="text" id="otp" placeholder="Verification code" style="text-align: center;" formControlName="otp">
          <div *ngIf="otpForm.get('otp').invalid && 
                                                        (otpForm.get('otp').dirty || otpForm.get('otp').touched)"
                                                class="error">
            <div *ngIf="otpForm.get('otp').errors.required">
                verification code is required.
            </div>

            <div *ngIf="otpForm.get('otp').errors.OTP && !otpForm.get('otp').errors.required">
              verification code is invalid.
            </div>                      
          </div>
        </div>
        <div>
          <button class="btn-otp"
          [disabled]="otpForm.invalid || loading"
          [ngClass]="{'btn-disabled': otpForm.invalid || loading}"
          type="submit">
          <span *ngIf="!loading">Verify Account</span>
          <span *ngIf="loading"><i class="fa fa-circle-notch fa-spin"></i> Verifying...</span>
        </button>
        </div>
        <div class="mt-4 text-center">
          <p class="text-muted" (click) = "onResendOTP()" style="font-weight: 400; cursor: pointer;"><i class="mr-1"></i>Didn't get sms/email? Resend</p>
      </div>
      </div>
    </form>
  </div>
</div>
