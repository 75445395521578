import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './auth/login/login.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { OTPComponent } from './auth/otp/otp.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotLoggedInGuard } from './shared/guards/not-logged-in';
import { LoggedInGuard } from './shared/guards/logged-in';
import { UserHomeComponent } from './user-home/user-home.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SearchCarparkComponent } from './search-carpark/search-carpark.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { IntroVideoModalComponent } from './home/intro-video-modal/intro-video-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { WebReferralComponent } from './auth/web-referral/web-referral.component';
import { WebReferralModalComponent } from './auth/web-referral/web-referral-modal/web-referral-modal.component';
import { TransactionComponent } from './transaction/transaction.component';
import { CommonModule } from '@angular/common';
import { PaymentCollectionModalComponent } from './transaction/payment-collection-modal/payment-collection-modal.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ProfileComponent } from './profile/profile.component';
import { ParkingHistoryComponent } from './parking-history/parking-history.component';
import { ParkingHistoryDetailModalComponent } from './parking-history/parking-history-detail-modal/parking-history-detail-modal.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ReservationPaymentSummaryComponent } from './reservation/widget/reservation-payment-summary/reservation-payment-summary.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignUpComponent,
    OTPComponent,
    UserHomeComponent,
    SearchCarparkComponent,
    WebReferralComponent,
    WebReferralModalComponent,
    TransactionComponent,
    PaymentCollectionModalComponent,
    ProfileComponent,
    ParkingHistoryComponent,
    ParkingHistoryDetailModalComponent,
    ReservationComponent,
    ReservationPaymentSummaryComponent
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    SharedModule,
    NgxSpinnerModule,
    FormsModule,
    GooglePlaceModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    NgxMaterialTimepickerModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    CarouselModule.forRoot()
  ],
  entryComponents: [IntroVideoModalComponent, WebReferralModalComponent],
  providers: [NotLoggedInGuard, LoggedInGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
