import { ParkingHistory } from './parking-history';

export class ParkingHistoryViewModel {
    amount: number;
    bookingDate: string;
    carparkAddress: string;
    carparkId: string;
    carparkName: string;
    checkInTime: string;
    checkOutTime: string;
    id: string;
    parkingDuration: string;
    parkingStatus: string;
    parkingFee: number;
    parkingLot: string;
    plateNumber: string;
    reservationDate: string;
    reservationPeriod: string;
    userId: string;
    validReservation: boolean;
    vehicleType: string;

    constructor(parkingHistory: ParkingHistory) {
        this.userId = parkingHistory.user_id;
        this.validReservation = parkingHistory.validReservation;
        this.vehicleType = parkingHistory.vehicleType;
        this.plateNumber = parkingHistory.plate_number;
        this.parkingStatus = parkingHistory.parkingStatus;
        this.parkingFee = parkingHistory.parking_fee;
        this.parkingLot = parkingHistory.parking_lot;
        this.amount = parkingHistory.amount;
        this.bookingDate = parkingHistory.booking_date;
        this.carparkAddress = parkingHistory.carpark_address;
        this.parkingDuration = parkingHistory.parkingDuration;
        this.reservationDate = parkingHistory.reservation_date;
        this.reservationPeriod = parkingHistory.reservation_period;
        this.carparkId = parkingHistory.carpark_id;
        this.carparkName = parkingHistory.carpark_name;
        this.id = parkingHistory.id;
        this.checkInTime = parkingHistory.checkInTime;
        this.checkOutTime = parkingHistory.checkOutTime;
        // this.bank = tranx.bank;
        // this.bonus = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.bonus);
        // this.carParkName = tranx.carParkName;
        // this.cardType = tranx.cardType;
        // this.cashBack = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.cashBack);
        // this.channel = tranx.channel;
        // this.creditAmount = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.creditAmount);
        // this.firstName = tranx.firstName;
        // this.id = tranx.id;
        // this.lastName = tranx.lastName;
        // this.paymentTransactionId = tranx.paymentTransactionId;
        // this.reservationAmount = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.reservationAmount);
        // this.status = tranx.status;
        // this.transactionDate = tranx.transactionDate;
        // this.transactionID = tranx.transactionID;
        // this.type = tranx.type;
        // this.userId = tranx.userId;
    }

    getAmountText() {
        return new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(this.amount);
    }

   getBookingDate() {
        const dateTime = this.bookingDate.split('-');
        return dateTime[0].trim();
    }

//     getTime() {
//         const dateTime = this.transactionDate.split('-');
//         return dateTime[1].trim();
//     }
}
