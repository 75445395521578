import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { SessionService } from '../services/session.service';

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private authenticationService: AuthenticationService,
                private router: Router, private sessionService: SessionService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree |
     Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authenticationService.tokenUserContextExist() === true) {
            return true;
        } else {
            this.sessionService.clearSession();
            this.router.navigateByUrl('/login');
        }
    }
}
