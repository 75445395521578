import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ParkingRate } from '../../models/parking-rate';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-carpark-rates',
  templateUrl: './carpark-rates.component.html',
  styleUrls: ['./carpark-rates.component.scss']
})
export class CarparkRatesComponent implements OnInit {

  parkingRates: ParkingRate[];

  constructor(public bsModalRef: BsModalRef,
              private dialogService: DialogService) { }

  ngOnInit() {
  }

  close() {
    this.dialogService.getModalSubject().next(null);
    this.bsModalRef.hide();
  }

  hasDayWeekId(dayWeekId: number): boolean {
    return this.parkingRates.some(x => x.dayOfWeekId === dayWeekId);
  }

  getDayWeekParkingRates(dayWeekId: number): ParkingRate[] {
    return this.parkingRates.filter(x => x.dayOfWeekId === dayWeekId);
  }

  getMONFRIAmountText(rate: ParkingRate) {

    if (rate.extra_hour_amount > 0){
      return `${new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN', maximumFractionDigits: 0  })
      .format(rate.amount)} @ ${new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN', maximumFractionDigits: 0  })
      .format(rate.extra_hour_amount)}/extra 1hr`;
    }
    else {
      return rate.amount;
    }
  }

  getSUNAmountText(rate: ParkingRate) {

    if (rate.extra_hour_amount > 0){
      return `${new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN', maximumFractionDigits: 0 })
      .format(rate.amount)} @ ${new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN', maximumFractionDigits: 0  })
      .format(rate.extra_hour_amount)}/extra 1hr`;
    }
    else {
      return rate.amount;
    }
  }

  getMONFRIDesciptionText(rate: ParkingRate) {
    return rate.description;
  }

  getSUNDesciptionText(rate: ParkingRate) {
    return rate.description;
  }

  showVehicleType(rate: ParkingRate) {
    return rate.extra_hour_amount > 0;
  }

  getVehicleImagePath(rate: ParkingRate) {
    let path = '../../../../assets/img/';

    switch (rate.vehicleType) {
      case 'Saloon car':
        return path += 'vehicle_sedan_car.png';
      case 'Bus':
        return path += 'vehicle_bus_side.png';
      case 'Motor Bike':
        return path += 'vehicle_bike.png';
      case 'Sport Utility Vehicle':
        return path += 'vehicle_suv.png';
      case 'Pick up':
        return path += 'vehicle_pickup.png';
      case 'Truck':
        return path += 'vehicle_truck.png';
      case 'Others':
        return path += 'vehicle_others.png';
      default:
        break;
    }
  }

}
