<div class="signup-page">
  <div class="card signup-container">
    <form [formGroup]="signupForm" (ngSubmit)="onSignup()">
      <div class="card-body signup-content">
        <div>
          <a routerLink="/home">
            <img class="signup-logo" src="../../../assets/img/parchive-logo.png" alt="Parchive logo">
          </a>
        </div>
        <div class="form-header">
          <h5>Create your user account</h5>
        </div>
        <div class="form-group">
          <label for="signup-firstname">First name</label>
          <input type="text" id="signup-firstname" placeholder="First name" formControlName="firstName">
          <div *ngIf="signupForm.get('firstName').invalid && 
                                                        (signupForm.get('firstName').dirty || signupForm.get('firstName').touched)"
                                                class="error">
            <div *ngIf="signupForm.get('firstName').errors.required">
                First name is required.
            </div>                     
          </div>
        </div>
        <div class="form-group">
          <label for="signup-lastname">Last name</label>
          <input type="text" id="signup-lastname" placeholder="Last name" formControlName="lastName">
          <div *ngIf="signupForm.get('lastName').invalid && 
                                                        (signupForm.get('lastName').dirty || signupForm.get('lastName').touched)"
                                                class="error">
            <div *ngIf="signupForm.get('lastName').errors.required">
                Last name is required.
            </div>                     
          </div>
        </div>
        <div class="form-group">
          <label for="signup-phone">Mobile number</label>
          <input type="text" id="signup-phone" placeholder="Mobile number" formControlName="phoneNumber">
          <div *ngIf="signupForm.get('phoneNumber').invalid && 
                                                        (signupForm.get('phoneNumber').dirty || signupForm.get('phoneNumber').touched)"
                                                class="error">
                                    <div *ngIf="signupForm.get('phoneNumber').errors.required">
                                        Mobile number is required.
                                    </div>

                                    <div *ngIf="signupForm.get('phoneNumber').errors.phoneNumber && !signupForm.get('phoneNumber').errors.required">
                                        Mobile number is invalid.
                                    </div>
                                </div>
        </div>
        <div class="form-group">
          <label for="signup-email">Email</label>
          <input type="email" id="signup-email" placeholder="Email address" formControlName="email">
          <div *ngIf="signupForm.get('email').invalid && 
                                                        (signupForm.get('email').dirty || signupForm.get('email').touched)"
                                                class="error">
            <div *ngIf="signupForm.get('email').errors.required">
              Email is required.
            </div>

            <div *ngIf="signupForm.get('email').errors.email">
              Email is invalid.
            </div>                      
          </div>
        </div>
        <div class="mt-3">
          <div class="form-group terms-container">
            <input type="checkbox" class="accept-terms" id="exampleCheck1" formControlName="acceptTerms">
            <p>By selecting Agree and continue below, you agree to Parchive's <a [href]="'https://parchive.space/terms-of-service/'" target="_blank">Terms and Conditions</a></p>
          </div>
          <!-- <p>By selecting Agree and continue below, you agree to Parchive's <a href="">Terms and Conditions</a></p> -->
        </div>
        <div>
          <button 
          [disabled]="signupForm.invalid || loading"
          class="btn-signup"
          [ngClass]="{'btn-disabled': signupForm.invalid || loading}"
          type="submit">
          <span *ngIf="!loading">Sign up</span>
          <span *ngIf="loading"><i class="fa fa-circle-notch fa-spin"></i> Signing up...</span>
        </button>
        </div>
        <div class="mt-4 text-center">
          <a [routerLink]="['/', 'login']" class="text-muted" style="font-weight: 400"><i class="mr-1"></i>Already have an Account? Login </a>
      </div>
      </div>
    </form>
  </div>
</div>


