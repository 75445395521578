<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <div class="parking-detail-qrcode-container">
        <app-qr-code [value]="getQRCodeValue()" [cssClass]="'profile-qrcode'"></app-qr-code>
      </div>
    </div>
    <div>
      <div class="parking-detail-basic-info">
        <div class="address">{{history.carparkName}}</div>
        <div class="address">{{history.carparkAddress}}</div>
        <div>{{history.plateNumber}}</div>
        <div>{{history.parkingLot}}</div>
      </div>
      <div class="divider"></div>
      <div class="parking-detail-info-container">
        <div class="parking-detail-info">
          <div>Status</div>
          <div class="info-values">{{history.parkingStatus}}</div>
        </div>
        <div class="parking-detail-info">
          <div>Date</div>
          <div class="info-values">{{history.reservationDate}}</div>
        </div>
        <div class="parking-detail-info">
          <div>Time</div>
          <div class="info-values">{{history.reservationPeriod}}</div>
        </div>
        <div class="parking-detail-info">
          <div>Actual fee</div>
          <div class="info-values">{{history.getAmountText()}}</div>
        </div>
        <div class="parking-detail-info">
          <div>Payment Method</div>
          <div class="info-values">In-App Payment</div>
        </div>
      </div>
    </div>
  </div>
</div>

