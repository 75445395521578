<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="reservation-charges-content">
      <div>
        <div class="table-responsive">
          <table class="table">
            
            <tbody>
                
                  <tr *ngFor="let fee of confirmationChargesInfo">
                    <td>
                      <div class="fee-title">
                        {{fee.title}}
                      </div>
                      <div class="fee-subtitle" *ngIf="fee.hasSubtitle">
                        {{fee.subtitle}}
                      </div>
                    </td>
                    <td>
                      <div class="fee-value">
                        &#8358;{{fee.value}}
                      </div>
                    </td>
                  </tr>
                
            </tbody>
          </table>

          <div>
            <p>Please note that a full-day parking fee is charged upfront but the balance will be refunded if less than 
              full-day parking is utilized.</p>
          </div>
          <button class="btn-reserve" (click)="Reserve()">Agree and Continue</button>
        </div>
      </div>
    </div>
  </div>

</div>


