<div class="page-container">
  <app-nav-bar [hasBkgColor]="true"></app-nav-bar>  
  <div class="reservation-container">
    <div class="reservation-content">
      <div class="card">
        <div class="card-body">
          <div class="img-container">
            <carousel [isAnimated]="true" [interval]="0">
              <slide *ngFor="let image of carparkInfo.image_urls">
                <img [src]="image" alt="first slide" style="display: block; width: 100%; height: 300px;">
              </slide>
            </carousel>
          </div>
          <div class="name_address_container">
            <h5 class="park-name">{{carparkInfo.carpark_name}}</h5>
            <p class="park-address">{{carparkInfo.carpark_address}}</p>
          </div>
          <div>
            <div>
              <div>
                <div class="available_time_container">
                  <div class="open_time">Opens: {{carparkInfo.opening_time}}</div>
                  <div class="close_time">Closes: {{carparkInfo.closing_time}}</div>
                </div>
                <div *ngIf="hasRates()" class="show-rate-container">
                  <button class="btn-show-rate" (click)="showRate()">Show Rates</button>
                </div>
                <div *ngIf="!hasRates()" class="show-flat-rate-container">
                  <button class="btn-flat-rate">&#8358; {{carparkInfo.flatFeeAmount}}</button>
                </div>
              </div>
            </div>
            <form class="reservation-form" [formGroup]="reservationForm">
              <div class="reservation-form-content">
                <div style="margin-bottom: 1rem;">
                  <div style="display: flex; justify-content:space-between">
                    <div style="flex: 0 0 80%;">
                      <select id="reservation-plate" formControlName="reservationPlate">
                        <option value="">Vehicle Licence Plate</option>
                        <option [value]="vehicle.plateNumber" *ngFor="let vehicle of registeredVehicles">{{vehicle.plateNumber}}-{{vehicle.category}}</option>
                      </select>
                      <!-- <div *ngIf="reservationForm.get('reservationPlate').invalid && 
                                                        (reservationForm.get('reservationPlate').dirty || reservationForm.get('reservationPlate').touched)"
                                                class="error">
                        <div *ngIf="reservationForm.get('reservationPlate').errors.required">
                            Vehicle license plate is required.
                        </div>                     
                      </div> -->
                    </div>
  
                    <div style="margin-bottom: 10px; display: flex;">
                      <button class="btn-show-select-plate" title="Select plate number" (click)="onAddVehicle()">&#43;</button>
                    </div>
                  </div>
                  <div *ngIf="reservationForm.get('reservationPlate').invalid && 
                                                        (reservationForm.get('reservationPlate').dirty || reservationForm.get('reservationPlate').touched)"
                                                class="error">
                        <div *ngIf="reservationForm.get('reservationPlate').errors.required">
                            Vehicle license plate is required.
                        </div>                     
                      </div>
                </div>
  
                <div class="form-group">
                  <input type="text" placeholder="Reservation Date" class="form-control"
                  formControlName="reservationDate"
                  bsDatepicker
                  [minDate]="minDate"
                  [readonly]="true"
                  [outsideClick]="true"
                  [bsConfig]="{ 
                    isAnimated: true,
                    adaptivePosition: true,
                    containerClass: 'theme-blue',
                    showTodayButton: true, 
                    dateInputFormat: 'DD-MM-YYYY'
                   }">
                  <div *ngIf="reservationForm.get('reservationDate').invalid && 
                                                        (reservationForm.get('reservationDate').dirty || reservationForm.get('reservationDate').touched)"
                                                class="error">
                                    <div *ngIf="reservationForm.get('reservationDate').errors.required">
                                      Reservation date is required.
                                    </div>

                                    <div *ngIf="reservationForm.get('reservationDate').errors.reservationDate && !reservationForm.get('reservationDate').errors.required">
                                      Reservation date is invalid.
                                    </div>
                                </div>
                </div>
  
                <!-- <div>
                  <div class="checkIn_checkOut_Container">
                    <div class="form-group" >
                      <label for="">Check In Time</label>
                      <timepicker formControlName="reservationCheckIn"></timepicker>
                      <div *ngIf="reservationForm.get('reservationCheckIn').invalid && 
                                                        (reservationForm.get('reservationCheckIn').dirty || reservationForm.get('reservationCheckIn').touched)"
                                                class="error">
                                    <div *ngIf="reservationForm.get('reservationCheckIn').errors.reservationCheckIn">
                                      check in time is invalid.
                                  </div>
                                </div>
                    </div>
                    <div class="form-group" >
                      <label for="">Check Out Time</label>
                      <timepicker formControlName="reservationCheckOut"></timepicker>
                      <div *ngIf="reservationForm.hasError('reservationCheckOut') && 
                                                        (reservationForm.get('reservationCheckOut').dirty || reservationForm.get('reservationCheckOut').touched)"
                                                        class="error">
                                    <div *ngIf="reservationForm.errors.reservationCheckOut">
                                      check out time is invalid.
                                    </div>
                                </div>
                    </div>
                  </div>
                </div> -->

                <div>
                  <div class="checkIn_checkOut_Container">
                    <div class="form-group time" >
                      <input placeholder="Check In Time" aria-label="Check In Time"
                [ngxTimepicker]="appendedToInput1" formControlName="reservationCheckIn" readonly>
            <ngx-material-timepicker #appendedToInput1
                [appendToInput]="false"></ngx-material-timepicker>
                      <div *ngIf="reservationForm.hasError('reservationCheckIn') && 
                                                        (reservationForm.get('reservationCheckIn').dirty || reservationForm.get('reservationCheckIn').touched)"
                                                class="error">
                                    <div *ngIf="reservationForm.errors.reservationCheckIn">
                                      check in time is invalid.
                                  </div>
                                </div>
                    </div>
                    <div class="form-group time" >
                      <input placeholder="Check Out Time" aria-label="Check Out Time"
                [ngxTimepicker]="appendedToInput2" formControlName="reservationCheckOut" readonly>
            <ngx-material-timepicker #appendedToInput2
                [appendToInput]="false" [defaultTime]=""></ngx-material-timepicker>
                      <div *ngIf="reservationForm.hasError('reservationCheckOut') && 
                                                        (reservationForm.get('reservationCheckOut').dirty || reservationForm.get('reservationCheckOut').touched)"
                                                        class="error">
                                    <div *ngIf="reservationForm.errors.reservationCheckOut">
                                      check out time is invalid.
                                    </div>
                                </div>
                    </div>
                  </div>
                </div>
  
                <div>
                  <button 
                  [ngClass]="{'btn-disabled': reservationForm.invalid }" 
                  type="submit"
                  [disabled]="reservationForm.invalid"
                  (click) ="onReserveNew()"
                  class="btn-reserve">Reserve Space</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

