<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you what to remove {{category}} with plate number {{plateNumber}}</p>

    <div class="btn-container">
      <button class="btn-cancel" (click)="close()">Cancel</button>
      <button class="btn-remove" (click)="removeVehicle()">Remove</button>
    </div>
  </div>
</div>

