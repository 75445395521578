import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  private BASE_URL = environment.baseUrl;

  constructor(private http: HttpClient) { }

verifyReservation(carparkId: string, reservationDate: string, plateNumber: string, reservationPeriod: string) {
  return this.http.post(this.BASE_URL,
    {
      query: '{ lookupParkingFee charges{ paymentGatewayPercentage paymentGatewayTransactionCharge_NGN parchiveTransactionFee_NGN transactionFeePercentage reservationFeePercentage carparkTransferPercentage reservationDeductionPercentage reservationDiscountPercentage convenienceFee}}',
      variables: {
          carparkId,
          reservationPeriod,
          reservationDate,
          plateNumber
      }
  });
}

makeReservation(carparkId: string, carparkName: string, plateNumber: string,
                reservationDate: string, reservationPeriod: string) {
  return this.http.post(this.BASE_URL,
    {
      operationName: 'MakeReservationMutation',
      query: 'mutation MakeReservationMutation { makeReservation {id carpark_id user_id} }',
      variables: {
          carpark_id: carparkId,
          carpark_name: carparkName,
          plate_number: plateNumber,
          reservation_date: reservationDate,
          reservation_period: reservationPeriod
      }
  });
}

}
