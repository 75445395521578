import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { Response } from '../../models/response';
import { DialogService } from '../../services/dialog.service';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-add-vehicle-modal',
  templateUrl: './add-vehicle-modal.component.html',
  styleUrls: ['./add-vehicle-modal.component.scss']
})
export class AddVehicleModalComponent implements OnInit, OnDestroy {

  addVehicleCollectionForm = new FormGroup({
    vehiclePlateNumber: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9]*')]),
    vehicleCategory: new FormControl('', [Validators.required])
  });

  private subscription: Subscription;

  constructor(public bsModalRef: BsModalRef,
              private profileService: ProfileService,
              private dialogService: DialogService,
              private toastService: ToastService) { }

  ngOnInit() {
  }

  onAddVehicle() {

    const category: string = this.addVehicleCollectionForm.get('vehicleCategory').value;
    const plateNumber: string = this.addVehicleCollectionForm.get('vehiclePlateNumber').value;

    this.subscription = this.profileService.addVehicle(category, plateNumber.replace(/[^a-zA-Z0-9]/g, '').trim().toUpperCase())
    .subscribe((response: Response<{addVehicle: boolean}>) => {
      if (response.meta.metaData.statusCode === 200) {
        if (response.data.addVehicle === true) {
          this.dialogService.getModalSubject().next(true);
          this.toastService.showSuccess('Vehicle added successfully', 'Success');
          this.bsModalRef.hide();
        } else {
          this.toastService.showError('Error occur while adding vehicle', 'Error');
        }
      } else {
        this.toastService.showError('Error occur while adding vehicle', 'Error');
      }
    }, () => {
      this.toastService.showError('Error occur while adding vehicle', 'Error');
    });
  }

  close() {
    this.dialogService.getModalSubject().next(null);
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
