import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { AppConstants } from '../shared/models/app-constants';
import { Carpark } from '../shared/models/carpark';
import { Response } from '../shared/models/response';
import { CarparkService } from '../shared/services/carpark.service';
import { ToastService } from '../shared/services/toast.service';
import { IntroVideoModalComponent } from './intro-video-modal/intro-video-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild('parkingLotCount') parkingLotCount: ElementRef<HTMLElement>;

  @ViewChild('homeInput') homeInput: ElementRef<HTMLInputElement>;

  carparkCount = 0;

  carparkSearchResult: Carpark[] = [];
  hasResult = false;

  parkImages = environment['carpak-images'];

  searchCoord: { latitude: string, longitude: string, searchAddress: string };

  options = {
    componentRestrictions: {
      country: ['NG']
    },
    fields: ['place_id', 'geometry']
  };

  shownavOnMobile = false;

  modalRef: BsModalRef;

  constructor(private viewportScroller: ViewportScroller,
              private carparkService: CarparkService,
              private toastService: ToastService,
              private modalService: BsModalService,
              private router: Router) { }

  ngOnInit() {
  }

  public onScrollToSection(elementId: string): void {
    document.getElementById(elementId).scrollIntoView({behavior: 'smooth'});
}

public handleAddressChange(address: any) {

  const latitude = address.geometry.location.lat();
  const longitude = address.geometry.location.lng();
  const searchAddress = this.homeInput.nativeElement.value;
  this.searchCoord = { longitude, latitude, searchAddress };

  this.searchCarpark(latitude, longitude);
}

toggleNav() {
 this.shownavOnMobile = !this.shownavOnMobile;
}

searchCarpark(latitude, longitude) {

  this.carparkService.getNearestCarparksByUnknown(latitude, longitude)
    .subscribe((response: Response<{nearby_parking: Carpark[]}>) => {

      if (response.meta.metaData.statusCode === 200 && response.data.nearby_parking.length > 0) {

        this.carparkSearchResult = response.data.nearby_parking;
        this.hasResult = true;
      }else {
        this.toastService.showInfo('No carpark close to you');
      }
    }, () => {
      this.toastService.showError('Error occured while searching for carparks', 'Error');
    });
}

public closeResult() {
  this.hasResult = false;
  this.carparkSearchResult = [];
}

onLogin(event: Carpark) {
  if (event) {

    window.localStorage.removeItem(AppConstants.PWCUSERSEARCHKEY);
    window.localStorage.setItem(AppConstants.PWCUSERSEARCHKEY, JSON.stringify(this.searchCoord));

    this.router.navigateByUrl('login');
  }
}

onScroll() {
  const position = this.parkingLotCount.nativeElement.getBoundingClientRect();

  if ((position.top < window.innerHeight && position.bottom >= 0) || (position.top >= 0 && position.bottom <= window.innerHeight)) {
    const interval = setInterval(() => {
      if (this.carparkCount !== 2000) {
       this.carparkCount = this.carparkCount + 5;
      } else{
        clearInterval(interval);
      }
    }, 1);
}
}

showIntro() {
  const config: ModalOptions = { class: 'modal-lg modal-dialog-centered intro', backdrop: 'static' };
  this.modalRef = this.modalService.show(IntroVideoModalComponent, config);
}

}
