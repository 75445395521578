import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Pager } from 'src/app/shared/models/pager';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private BASE_URL = environment.baseUrl;

  private paymentSubject = new Subject<any>();

constructor(private http: HttpClient) { }

getUserTransactions(pager: Pager) {
  return this.http.post(this.BASE_URL,
    {
      query: '{transactions {id, transactionID, transactionDate, channel cardType type creditAmount bank status userId firstName lastName carParkName reservationAmount cashBack paymentTransactionId bonus}}',
      variables: {
          page: pager.page,
          pageSize: pager.pageSize
      }
    },
    // {
    //   headers: new HttpHeaders({'require-token': 'true'})
    // }
    );
}

getUserBalance() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ balance }'
    },
    // {
    //   headers: new HttpHeaders({'require-token': 'true'})
    // }
    );
}

getTransactionCount() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ transactionCount }'
    },
    // {
    //   headers: new HttpHeaders({'require-token': 'true'})
    // }
    );
}

verifyPayment(refernce: string, amount: number) {
  return this.http.post(this.BASE_URL,
    {
      operationName: 'creditUserMutation',
      query: 'mutation creditUserMutation { creditUser }',
      variables: {
          refId: refernce,
          amount
      }
    },
    // {
    //   headers: new HttpHeaders({'require-token': 'true'})
    // }
    );
}

getCharges() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ charges{ paymentGatewayPercentage paymentGatewayTransactionCharge_NGN parchiveTransactionFee_NGN transactionFeePercentage reservationFeePercentage carparkTransferPercentage reservationDeductionPercentage reservationDiscountPercentage convenienceFee} }'
    },
    // {
    //   headers: new HttpHeaders({'require-token': 'true'})
    // }
    );
}

paymentSubjectAsObservable() {
  return this.paymentSubject.asObservable();
}

getPaymentSubject() {
  return this.paymentSubject;
}

getPaymentReference(topupAmount: number, topupTotalAmount: number) {
  return this.http.post(this.BASE_URL,
    {
      operationName: 'BalanceTopupPaymentReferenceMutation',
      query: 'mutation BalanceTopupPaymentReferenceMutation { balanceTopupPaymentReference }',
      variables: {
          topupAmount,
          topupTotalAmount
      }
    }
  );
}

}
