import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { RegisteredVehicle } from '../../models/registered-vehicle';
import { ToastService } from '../../services/toast.service';
import { Response } from '../../models/response';

@Component({
  selector: 'app-my-access-code',
  templateUrl: './my-access-code.component.html',
  styleUrls: ['./my-access-code.component.scss']
})
export class MyAccessCodeComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  passCode: string;
  timer = 0;
  progress = 0;
  showGenAccess = true;

  constructor(private bsModalRef: BsModalRef,
              private toastService: ToastService,
              private profileService: ProfileService,
              ) { }

  ngOnInit() {
  }

  onGenerateAccessCode() {

    this.subscriptions.push(this.profileService.getPassCode()
      .subscribe((passCode: Response<{ generatePasscode: number }>) => {
        if (passCode.meta.metaData.statusCode === 200) {
          this.passCode = passCode.data.generatePasscode.toString();
          this.startCountDown();
        } else {
          this.toastService.showError('Couldn\'t display your Access code ', 'Error');
        }
      }, () => {
        this.toastService.showError('Couldn\'t display your Access code ', 'Error');
      }));
  }

  startCountDown() {
    this.showGenAccess = false;
    this.timer = 59;
    this.progress = this.calculateProgress();

    const interval = setInterval(() => {
      if (this.timer === 0) {
        this.showGenAccess = true;
        this.passCode = '';
        clearInterval(interval);
      }
      else {
        this.timer = this.timer - 1;
        this.progress = this.calculateProgress();
      }

    }, 1000);
  }

  calculateProgress() {
    return (this.timer * 100) / 60;
  }

  close() {
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
