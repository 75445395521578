import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Carpark } from '../../models/carpark';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-carpark',
  templateUrl: './carpark.component.html',
  styleUrls: ['./carpark.component.scss']
})
export class CarparkComponent implements OnInit {

  @Input() carparkInfo: Carpark;
  @Output() carparkEvent = new EventEmitter<any>();

  averageWalkingSpeed = 1.4;
  sec = 60;

  constructor(private authService: AuthenticationService, private router: Router) { }

  ngOnInit() {
  }

  getCapacity() {
    const lotsInUse = this.carparkInfo.parking_lots.filter(l => l.status === 1).length;
    const lotsInUsePer = parseInt(((lotsInUse / this.carparkInfo.capacity) * 100).toString(), 10);
    return lotsInUsePer;
  }

  getTimeAwayText() {
    const timeAway = (parseInt(this.carparkInfo.distance.toString(), 10) / this.averageWalkingSpeed) / this.sec;

    const time = Math.round(timeAway);
    let timeAwayText = '';

    if (time === 1) {
      timeAwayText = `${time} minute away on foot`;
    } else {
      timeAwayText = `${time} minutes away on foot`;
    }

    return timeAwayText;
  }

  getRateText() {
    let ratePerHrText = '';

    if (this.carparkInfo.feeType === 'Custom') {
      const amounts: number[] = [];

      this.carparkInfo.parkingRates.forEach(parkingRate => {
        amounts.push(parkingRate.amount);
      });

      const min = Math.min(...amounts);
      const max = Math.max(...amounts);

      ratePerHrText = `NGN${min} - NGN${max}`;
    }else if (this.carparkInfo.feeType === 'Per Hour') {
      ratePerHrText = `NGN${this.carparkInfo.flatFeeAmount} per hr`;
    }else if (this.carparkInfo.feeType === 'Flat') {
      ratePerHrText = `NGN${this.carparkInfo.flatFeeAmount} per day`;
    }

    return ratePerHrText;
  }

  getCapacityProgressWidth() {
    return `${this.getCapacity()}%`;
  }

  getButtonText() {

    if (this.authService.tokenUserContextExist()) {
      return 'Reserve';
    } else {
      return 'Login';
    }
  }

  onReserveOrLogin() {
    this.carparkEvent.emit(this.carparkInfo);
  }

}
