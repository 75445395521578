<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="paymentCollectionForm" (ngSubmit)="onContinue()">
      <div class="card-body payment-content">
        <div class="form-header">
          <h5>Enter top up amount</h5>
        </div>
        <div class="form-group">
          <label for="payment-firstname">First name</label>
          <input type="text" class="input-disabled" id="payment-firstname" placeholder="First name" formControlName="firstName">
        </div>
        <div class="form-group">
          <label for="payment-lastname">Last name</label>
          <input type="text" class="input-disabled" id="payment-lastname" placeholder="Last name" formControlName="lastName">
        </div>
        <div class="form-group">
          <label for="payment-email">Email</label>
          <input type="text" class="input-disabled" id="payment-email" placeholder="Email" formControlName="email">
        </div>
        <div class="form-group">
          <label for="payment-amount">Amount (500 and above)</label>
          <input type="text" id="payment-amount" placeholder="Enter top-up amount" formControlName="amount">
          <div *ngIf="paymentCollectionForm.get('amount').invalid && 
                                                        (paymentCollectionForm.get('amount').dirty || paymentCollectionForm.get('amount').touched)"
                                                class="error">
            <div *ngIf="paymentCollectionForm.get('amount').errors.required">
                Amount is required.
            </div> 
            <div *ngIf="paymentCollectionForm.get('amount').errors.amount && !paymentCollectionForm.get('amount').errors.required">
              {{paymentCollectionForm.get('amount').errors.amount.error}}
            </div>                    
          </div>
        </div>
        <div *ngIf="paymentCollectionForm.get('amount').valid">
          <div class="transactionfees">
            <div class="transactionfees-text">
              Transaction amount
            </div>
            <div class="transactionfees-fee" *ngIf="paymentCollectionForm.get('amount').valid">
              {{amountText}}
            </div>
          </div>
          <div class="transactionfees">
            <div class="transactionfees-text">
              Transaction fess
            </div>
            <div class="transactionfees-fee" *ngIf="paymentCollectionForm.get('amount').valid">
              {{transactionFeeText}}
            </div>
          </div>
          <div class="transactionfees transactionfees-fee-total">
            <div class="transactionfees-text">
              Total Amount
            </div>
            <div class="transactionfees-fee" *ngIf="paymentCollectionForm.get('amount').valid">
              {{totalFeeText}}
            </div>
          </div>
        </div>
        <div>
          <button 
          [disabled]="paymentCollectionForm.invalid"
          class="btn-payment-continue"
          [ngClass]="{'btn-disabled': paymentCollectionForm.invalid}"
          type="submit">
          Continue
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

