import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-share-modal',
  templateUrl: './invite-modal.component.html',
  styleUrls: ['./invite-modal.component.scss']
})
export class InviteModalComponent implements OnInit {

  inviteForm = new FormGroup({
    inviteMessage: new FormControl({value: '', disabled: true}, [Validators.required]),
  });

  inviteLink: string;

  constructor(private bsModalRef: BsModalRef, private toastService: ToastService) { }

  ngOnInit() {
    const inviteMessage = `Hey, Have you tried Parchive? I use it daily to find, reserve, and pay for parking spaces. Sign up with my link ${this.inviteLink} and you'll get NGN500 after you load your wallet with a minimum of NGN1000.`;
    this.inviteForm.patchValue({inviteMessage});
  }

  copyToClip(invite: HTMLTextAreaElement) {
    invite.disabled = false;
    invite.select();
    document.execCommand('copy');
    invite.setSelectionRange(0, 0);
    invite.disabled = true;
    this.toastService.showSuccess('Copied to clipboard!');
  }

  close() {
    this.bsModalRef.hide();
  }

}
