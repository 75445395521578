<div class="home-container">
  <header>
    <div class="cust-nav-toggle">
      <div>
        <span (click)="toggleNav()" class="material-icons mt-2 mr-2" style="color: #fff;cursor: pointer;">menu</span>
      </div>
    </div>
    <div class="menu-container" [ngClass]="{'show-nav': shownavOnMobile}">
      <div class="logo-nav">
        <div class="logo">
          <img src="../../assets/img/parchive-logo.png" alt="Parchive logo">
        </div>
        <div class="home-nav" >
          <ul>
            <li><a [href]="'https://play.google.com/store/apps/details?id=ng.inc.parchive&ah=NCUksgxDi2uwRSw5ViNsptvQJ7A'" target="_blank">Download App</a></li>
            <!-- <li><a routerLink="" (click)="onScrollToSection('merchant')">Become a Merchant</a></li>
            <li><a routerLink="" (click)="onScrollToSection('business')">Parchive for Business</a></li> -->
            <li><a routerLink="/login">Login</a></li>
            <li><a routerLink="/signup">Signup</a></li>
            <!-- <li><a href="#">Blog</a></li> -->
          </ul>
        </div>
      </div>
    </div>
    
    
    <div class="header-info-box">
      <div class="mobile-logo">
        <img src="../../assets/img/parchive-logo.png" alt="Parchive logo">
      </div>
      <div>
        <h1>find&#44; reserve and pay for parking with ease</h1>
      </div>
      <div class="search-destination">
        <form action="">
          <input #homeInput type="text" (keyup)="closeResult()" placeholder="Enter destination ..." ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
        </form>
      </div>
      <div class="watch-intro"><a routerLink="" class="cust-btn" (click)="showIntro()">Watch Product Intro</a></div>
    </div>
  </header>
  <!-- <section (window:scroll)= "onScroll()" class="app-basic-info">
    <div class="section-container">
      <div class="section-heading" style="padding-top: 30px;">
        <p class="park-count" #parkingLotCount>{{carparkCount}}</p>
        <p class="park-lot">Parking Lots and Counting ...</p>
      </div>
      <div class="basic-infos">
        <div class="basic-info">
          <div class="basic-info-img">
            <img [src]="parkImages.image1" alt="Easy use">
          </div>
        </div>
        <div class="basic-info">
          <div class="basic-info-img">
            <img [src]="parkImages.image2" alt="Easy use">
          </div>
        </div>
        <div class="basic-info">
          <div class="basic-info-img">
            <img [src]="parkImages.image3" alt="Easy use">
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section class="app-benefits">
    <div class="section-container">
      <div class="section-heading">
        <h2>User benefits</h2>
      </div>
      <div class="benefits">
        <div class="benefit-box">
          <div class="benefit-img-container">
            <img src="../../assets/img/easy-use.png" alt="Easy use">
          </div>
          <div>
            <h3>Ease of Use</h3>
            <p>We believe complex and stressful task like parking your car shouldn’t add to things you have to bother about. Parchive simplifies parking your car with an easy to use application.</p>
          </div>
        </div>
        <div class="benefit-box">
          <div class="benefit-img-container">
            <img src="../../assets/img/one-click-reservation.png" alt="Easy use">
          </div>
          <div>
            <h3>One click Reservation</h3>
            <p>There are moments when you just run out of luck because you were few seconds behind and you missed the last parking spot. Parchive has a fix for that with the One click Reservation called Parchive Radar. Parchive Radar instantly reserves the nearest car park with the click of a button.</p>
          </div>
        </div>
        <div class="benefit-box">
          <div class="benefit-img-container">
            <img src="../../assets/img/easy-payment.png" alt="Easy use">
          </div>
          <div>
            <h3>Frictionless Payment</h3>
            <p>Time is always precious and also because your time is money. Parchive wallet and frictionless payments makes parking transaction as easy as breathing. Yes, it is that fast and effortless.</p>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section class="merchant-benefits">
    <div class="section-container">
      <div class="section-heading">
        <h2>Merchant benefits</h2>
      </div>
      <div class="merchant-benefits">
        <div class="merchant-benefit-box">
          <div class="merchant-benefit-img-container">
            <img src="../../assets/img/reporting.png" alt="Easy use">
          </div>
          <div>
            <h3>Real Time Reporting</h3>
            <p>Automatically get updates on parking reservations made by drivers or car owners.</p>
          </div>
        </div>
        <div class="merchant-benefit-box">
          <div class="merchant-benefit-img-container">
            <img src="../../assets/img/easy-payment.png" alt="Easy use">
          </div>
          <div>
            <h3>Frictionless Payment</h3>
            <p>Parking payment has never been this easy. Get credited fast and easy.</p>
          </div>
        </div>
        <div class="merchant-benefit-box">
          <div class="merchant-benefit-img-container">
            <img src="../../assets/img/intelligent-pricing.png" alt="Easy use">
          </div>
          <div>
            <h3>Intelligent Pricing</h3>
            <p>We can tell when the demand for car park rises, so with this, we can add a price surge to increase how much you make.</p>
          </div>
        </div>
      </div>
      <div class="merchant-benefits">
        <div class="merchant-benefit-box">
          <div class="merchant-benefit-img-container">
            <img src="../../assets/img/reservation.png" alt="Easy use">
          </div>
          <div>
            <h3>Efficient Reservation System</h3>
            <p>Drivers and car owners can get a trusted and reliable reservation system.</p>
          </div>
        </div>
        <div class="merchant-benefit-box">
          <div class="merchant-benefit-img-container">
            <img src="../../assets/img/data-analytics.png" alt="Easy use">
          </div>
          <div>
            <h3>Parking Data Analytics</h3>
            <p>And if you want to get some general information about your car park usage, we have that feature also.</p>
          </div>
        </div>
        <div class="merchant-benefit-box">
          <div class="merchant-benefit-img-container">
            <img src="../../assets/img/operation.png" alt="Easy use">
          </div>
          <div>
            <h3>Seamless Operations</h3>
            <p>It’s just simple & efficient to operate a car park with our Platform.</p>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <section id="merchant" class="merchant-signup">
    <div class="section-container">
      <div class="section-heading merchant-signup-heading">
        <h2>Become a Merchant</h2>
      </div>
      <div class="merchant-signup-text">
        <h3>To join our ever-expanding network of car parks,<br> please fill the form below, so we can reach you.</h3>
      </div>
      <div>
        <form action="">
          <div class="merchant-signup-inputs">
            <div class="merchant-signup-inputs-container">
              <div class="merchant-signup-inputs-item">
                <input type="text" placeholder="Name">
              </div>
              <div class="merchant-signup-inputs-item">
                <input type="text" placeholder="Mobile Number">
              </div>
            </div>
            <div class="merchant-signup-inputs-container">
              <div class="merchant-signup-inputs-item">
                <input type="text" placeholder="Location/Area">
              </div>
              <div class="merchant-signup-inputs-item merchant-signup-input-last">
                
                <button class="cust-button">Send</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section> -->
  <!-- <section id="business">
    <div class="section-container">
      <div class="section-heading">
        <h2>Parchive for business</h2>
      </div>
      <div class="parchive-business-container">
        <div class="parchive-business-item">
          <div>
            <h3>Parking for your Employees sucks?</h3>
            Don’t worry, we’ve got you covered.
            With Parchive for Businesses,
            your employees don’t have to worry about
            searching and paying for parking.
          </div>
          <div>
            <img src="../../assets/img/business.png" alt="">
          </div>
        </div>
        <div class="parchive-business-item business-form">
          <div>
            <input class="parchive-business-input-1" type="text" placeholder="First Name">
            <input class="parchive-business-input-2" type="text" placeholder="Last Name">
          </div>
          <div>
            <input type="text" placeholder="Organization">
          </div>
          <div>
            <input type="text" placeholder="Organization Size">
          </div>
          <div>
            <input type="text" placeholder="Phone Number">
          </div>
          <div>
            <input type="text" placeholder="Email Address">
          </div>
          <div class="parchive-business-last-container">
            <button class="cust-button">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </section> -->
  <!-- <footer>
    <section>
      <div class="section-container">
        <div class="footer-container">
          <div class="footer-logo footer-item">
            <img src="../../assets/img/Parchive_Logo_White.png" alt="">
          </div>
          <ul class="footer-item"><span>Product</span>
            <li>Parchive User</li>
            <li>Parchive Merchant</li>
          </ul>
          <ul class="footer-item"><span>Company</span>
            <li><a href="">About us</a></li>
            <li><a href="">Blog</a></li>
            <li><a href="">Contact us</a></li>
          </ul>
          <ul class="footer-item"><span>Support</span>
            <li><a href="">Privacy policy</a></li>
            <li><a href="">Terms of Service</a></li>
          </ul>
        </div>
      </div>
    </section>
  </footer> -->

  <!-- begining -->

  <div [ngClass]="{'search-result-container': hasResult, 'search-result-container-hide': !hasResult}">
    <div class="d-flex justify-content-end">
      <button class="close-btn" (click)="closeResult()">&times;</button>
    </div>
    
    <div class="search-result" [ngClass]="{'search-result-one': carparkSearchResult.length === 1}">
      <div *ngFor="let result of carparkSearchResult" class="search-result-item">
        <app-carpark (carparkEvent)="onLogin($event)" [carparkInfo]="result"></app-carpark>
      </div>
    </div>
  </div>

  <!-- End -->

</div>
