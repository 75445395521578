import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as mapboxgl from 'mapbox-gl';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from 'src/environments/environment';
import { Carpark } from '../shared/models/carpark';
import { Response } from '../shared/models/response';
import { CarparkService } from '../shared/services/carpark.service';
import { ToastService } from '../shared/services/toast.service';

@Component({
  selector: 'app-search-carpark',
  templateUrl: './search-carpark.component.html',
  styleUrls: ['./search-carpark.component.scss']
})
export class SearchCarparkComponent implements OnInit, AfterViewInit {

  @ViewChild('mapInput') mapInput: ElementRef;

  carparkSearchResult: Carpark[] = [];
  markers: mapboxgl.Marker[] = [];

  map: mapboxgl.Map;
  style = 'mapbox://styles/parchive-technologies/cjv2nco4t036v1fmz94qguzj2';

  lat = 6.5244;
  lng = 3.3792;

  options = {
    componentRestrictions: {
      country: ['NG']
    },
    fields: ['place_id', 'geometry']
  };

  hasResult = false;
  searchPayload: any;

  constructor(private carparkService: CarparkService,
              private toastService: ToastService,
              private router: Router)
              {
                const state = this.router.getCurrentNavigation().extras.state;
                this.searchPayload = state ? state.searchPayload : undefined;
              }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.map = new mapboxgl.Map({
          container: 'map',
          style: this.style,
          zoom: 15,
          pitch: 60,
          accessToken: environment.mapboxAccessToken,
          center: [this.lng, this.lat]
      });

        if (this.searchPayload) {
        const searchText = this.searchPayload.searchAddress;
        this.mapInput.nativeElement.value = searchText;

        this.searchCarpark(this.searchPayload.latitude, this.searchPayload.longitude, this.searchPayload.searchCategory);
      }
      }, () => {
        this.map = new mapboxgl.Map({
          container: 'map',
          style: this.style,
          zoom: 15,
          pitch: 60,
          accessToken: environment.mapboxAccessToken,
          center: [this.lng, this.lat]
      });

        if (this.searchPayload) {
        const searchText = this.searchPayload.searchAddress;
        this.mapInput.nativeElement.value = searchText;

        this.searchCarpark(this.searchPayload.latitude, this.searchPayload.longitude, this.searchPayload.searchCategory);
      }
      });
    } else {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: this.style,
        zoom: 15,
        pitch: 60,
        accessToken: environment.mapboxAccessToken,
        center: [this.lng, this.lat]
    });

      if (this.searchPayload) {
      const searchText = this.searchPayload.searchAddress;
      this.mapInput.nativeElement.value = searchText;

      this.searchCarpark(this.searchPayload.latitude, this.searchPayload.longitude, this.searchPayload.searchCategory);
    }
    }
  }

  public handleAddressChange(address: any) {

    const latitude = address.geometry.location.lat();
    const longitude = address.geometry.location.lng();

    this.searchCarpark(latitude, longitude, 'destination');
}

public clearResult() {
  this.hasResult = false;
  this.carparkSearchResult = [];
  this.removeMarkers();
}

public onResultClose() {
  this.hasResult = false;
  this.mapInput.nativeElement.value = '';
  this.carparkSearchResult = [];
  this.removeMarkers();
}

addParchiveMarkers(carParks: Carpark[]) {
  carParks.forEach((carpark) => {
    const el = document.createElement('div');

    el.className = 'marker-parchive';

    this.markers.push(new mapboxgl.Marker(el, { offset: [0, 0] })
    .setLngLat([Number(carpark.longitude), Number(carpark.latitude)])
    .addTo(this.map));
  });
}

searchCarpark(latitude, longitude, searchCategory) {

  this.carparkService.getNearestCarparks(latitude, longitude, searchCategory)
    .subscribe((response: Response<{nearbyCarparks: Carpark[]}>) => {

      if (response.meta.metaData.statusCode === 200 && response.data.nearbyCarparks.length > 0) {
        this.map.flyTo({
          animate: true,
          center: [
            longitude,
            latitude
          ],
          zoom: 15
        });

        this.addDestinationMarkers(longitude, latitude);
        this.addParchiveMarkers(response.data.nearbyCarparks);
        this.carparkSearchResult = response.data.nearbyCarparks;
        this.hasResult = true;
      }else {
        this.toastService.showInfo('No Carpark close to your destination');
      }

    }, () => {
      this.toastService.showError('Error occured while searching for carparks', 'Error');
    });
}

addDestinationMarkers(longitude, latitude) {

  const el = document.createElement('div');

  el.className = 'marker-destination';

  this.markers.push(new mapboxgl.Marker(el, { offset: [0, 0] })
    .setLngLat([Number(longitude), Number(latitude)])
    .addTo(this.map));

}

removeMarkers() {
  this.markers.forEach(marker => {
    marker.remove();
  });

  this.markers = [];
}


onReserve(event: Carpark) {
  this.router.navigateByUrl(`/user/reservation/${event.carpark_id}`, {state: {carparkInfo: event, fromMap: true}});
}

}
