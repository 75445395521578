import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Response } from 'src/app/shared/models/response';
import { ToastService } from 'src/app/shared/services/toast.service';
import { DialogService } from '../../../shared/services/dialog.service';
import { Confirmation } from '../../models/confirmation';
import { VerifyReservation } from '../../models/verify-reservation';
import { ReservationService } from '../../services/reservation.service';

@Component({
  selector: 'app-reservation-payment-summary',
  templateUrl: './reservation-payment-summary.component.html',
  styleUrls: ['./reservation-payment-summary.component.scss']
})
export class ReservationPaymentSummaryComponent implements OnInit, OnDestroy {
  data: VerifyReservation;
  reservationInfo;
  parkingFeeTxt = 'Parking fee';
  serviceFeeTxt = 'Service fee';
  serviceFeeDescTxt = 'With this, we can run our platform and offer uninterrupted service and support';
  convenienceAmount = 'Convenience fee';
  total = 'Total';
  reservationFee = 'Reservation fee';
  confirmationChargesInfo: Confirmation[];
  private subscriptions: Subscription[] = [];

  constructor(public bsModalRef: BsModalRef,
              private dialogService: DialogService,
              private reservationService: ReservationService,
              private toastService: ToastService) { }

  ngOnInit() {
    if (this.data.charges.reservationDiscountPercentage > 0) {
      const reservationDiscount = this.data.lookupParkingFee -
      ((this.data.charges.reservationDiscountPercentage * this.data.lookupParkingFee) / 100.0);
      const serviceCharge = (this.data.charges.reservationFeePercentage / 100.0) * reservationDiscount;
      const total = serviceCharge + reservationDiscount + this.data.charges.convenienceFee;

      const discountConfirmationData = new Confirmation(this.parkingFeeTxt, '', this.data.lookupParkingFee ,
      false, this.data.charges.reservationDiscountPercentage);
      const serviceConfirmationData = new Confirmation(this.serviceFeeTxt, this.serviceFeeDescTxt, serviceCharge, true, 0);
      const convenienceConfirmationData = new Confirmation(this.convenienceAmount, '', this.data.charges.convenienceFee, false, 0);
      const totalConfirmationData = new Confirmation(this.total, '', total, false, 0);
      this.confirmationChargesInfo = [discountConfirmationData, convenienceConfirmationData,
        serviceConfirmationData, totalConfirmationData];
    }else {
      const serviceCharge = (this.data.charges.reservationFeePercentage / 100.0) * this.data.lookupParkingFee;
      const total = serviceCharge + this.data.lookupParkingFee + this.data.charges.convenienceFee;

      const serviceConfirmationData = new Confirmation(this.serviceFeeTxt, this.serviceFeeDescTxt, serviceCharge, true, 0);
      const parkingFeeConfirmationData = new Confirmation(this.parkingFeeTxt, '', this.data.lookupParkingFee, false, 0);
      const convenienceConfirmationData = new Confirmation(this.reservationFee, '', this.data.charges.convenienceFee, false, 0);
      const totalConfirmationData = new Confirmation(this.total, '', total, false, 0);
      this.confirmationChargesInfo = [parkingFeeConfirmationData, convenienceConfirmationData,
        serviceConfirmationData, totalConfirmationData];
  }
  }

  close() {
    this.dialogService.getModalSubject().next(null);
    this.bsModalRef.hide();
  }

  Reserve() {
    this.subscriptions.push(this.reservationService.makeReservation(this.reservationInfo.carparkId,
                                            this.reservationInfo.carparkName,
                                            this.reservationInfo.plateNumber,
                                            this.reservationInfo.reservationDate,
                                            this.reservationInfo.reservationPeriod)
    .subscribe((response: Response<{}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.dialogService.getModalSubject().next('successful');
        this.bsModalRef.hide();
      } else {
        this.toastService.showError('An error occured during reservation');
      }
    }, () => {
      this.toastService.showError('An error occured during reservation');
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
