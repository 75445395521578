import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading = false;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
  }

  onLogin() {
    this.loading = true;
    const sub = this.authService.resCompleteAsObservable().subscribe(() => {
      this.loading = false;
      sub.unsubscribe();
    });

    this.authService.loginUser(this.loginForm.get('email').value);
  }
}
