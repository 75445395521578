<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="referralForm" (ngSubmit)="onSignup()">
      <div class="card-body referral-content">
        <div class="form-header">
          <h5>Create your user account</h5>
        </div>
        <div class="form-group">
          <label for="referral-firstname">First name</label>
          <input type="text" id="referral-firstname" placeholder="First name" formControlName="firstName">
          <div *ngIf="referralForm.get('firstName').invalid && 
                                                        (referralForm.get('firstName').dirty || referralForm.get('firstName').touched)"
                                                class="error">
            <div *ngIf="referralForm.get('firstName').errors.required">
                First name is required.
            </div>                     
          </div>
        </div>
        <div class="form-group">
          <label for="referral-lastname">Last name</label>
          <input type="text" id="referral-lastname" placeholder="Last name" formControlName="lastName">
          <div *ngIf="referralForm.get('lastName').invalid && 
                                                        (referralForm.get('lastName').dirty || referralForm.get('lastName').touched)"
                                                class="error">
            <div *ngIf="referralForm.get('lastName').errors.required">
                Last name is required.
            </div>                     
          </div>
        </div>
        <div class="form-group">
          <label for="referral-phone">Mobile number</label>
          <input type="text" id="referral-phone" placeholder="Mobile number" formControlName="phoneNumber">
          <div *ngIf="referralForm.get('phoneNumber').invalid && 
                                                        (referralForm.get('phoneNumber').dirty || referralForm.get('phoneNumber').touched)"
                                                class="error">
                                    <div *ngIf="referralForm.get('phoneNumber').errors.required">
                                        Mobile number is required.
                                    </div>

                                    <div *ngIf="referralForm.get('phoneNumber').errors.phoneNumber && !referralForm.get('phoneNumber').errors.required">
                                        Mobile number is invalid.
                                    </div>
                                </div>
        </div>
        <div class="form-group">
          <label for="referral-email">Email</label>
          <input type="email" id="referral-email" placeholder="Email address" formControlName="email">
          <div *ngIf="referralForm.get('email').invalid && 
                                                        (referralForm.get('email').dirty || referralForm.get('email').touched)"
                                                class="error">
            <div *ngIf="referralForm.get('email').errors.required">
              Email is required.
            </div>

            <div *ngIf="referralForm.get('email').errors.email">
              Email is invalid.
            </div>                      
          </div>
        </div>
        <div class="mt-3">
          <div class="form-group terms-container">
            <input type="checkbox" class="accept-terms" id="exampleCheck1" formControlName="acceptTerms">
            <p>By selecting Agree and continue below, you agree to Parchive's <a [href]="'https://parchive.space/terms-of-service/'" target="_blank">Terms and Conditions</a></p>
          </div>
        </div>
        <div>
          <button 
          [disabled]="referralForm.invalid || loading"
          class="btn-referral"
          [ngClass]="{'btn-disabled': referralForm.invalid || loading}"
          type="submit">
          <span *ngIf="!loading">Sign up</span>
          <span *ngIf="loading"><i class="fa fa-circle-notch fa-spin"></i> Signing up...</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
