<div class="referral-container" *ngIf="referralInfo">
  <app-web-referral-nav-bar></app-web-referral-nav-bar>
  <div class="content-box">
    <div class="content-box_fcontainer">
      <div class="content-box__text pr-2">
        <h1>Find&#44; reserve<br> and pay for parking<br> with ease.</h1>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="page_info">
            <div class="page_info_item1"> 
              <h5>Get NGN 500 off your first Car park reservation when you join {{referralInfo.firstName}} on Parchive.</h5>
              <p>If you do not have an account on Parchive, your invite link remains valid for the next 14 days.</p>

              <div class="usage_indicator mt-5">
                <span class="initials">{{refereeInitials}}</span>
                <div class="ml-3">
                  <div class="referee_name">{{referralInfo.firstName}}</div>
                  <div>Has used Parchive {{referralInfo.total}} times</div>
                </div>
              </div>
            </div>
            <div  class="page_info_item2">
              <ul class="highlight">
                <li>Park as low as NGN 100.</li>
                <li>Save the extra fuel used for locating car parks for more important trips.</li>
                <li>You can finally keep your change.</li>
              </ul>
              <div class="mt-5 claim-invite-btn-container">
                <button class="claim-invite-btn" (click)="showRefferalForm()">Accept your invite</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </div>
  
</div>
