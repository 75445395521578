<!-- <div class="login-page my-5 pt-sm-5">
  <div class="container">
      <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
              <div class="card overflow-hidden">
                  <div class="card-body pt-0"> 
                    <div>
                        <div class="text-center mt-3">
                            <span class="">
                                <img src="../../../assets/img/parchive-logo.png" class="img-fluid" height="34">
                            </span>
                        </div>
                    </div>
                      <div class="p-2 mt-3">
                          <form class="form-horizontal">

                              <div class="form-group">
                                  <label for="username">Email</label>
                                  <input type="text" class="form-control" id="email" name="email"  placeholder="Enter email">
                              </div>
      
                              <div class="form-group">
                                  <label for="userpassword">Password</label>
                                  <input type="password" class="form-control" id="userpassword" name="password" placeholder="Enter password">
                              </div>

                              <div class="mt-3">
                                  <button  class="btn btn-primary btn-block waves-effect waves-light">Log In</button>
                              </div>

                              <div class="mt-4 text-center">
                                  <a [routerLink]="['/','forgot-password']" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot your password?</a>
                              </div>
                          </form>
                      </div>
  
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->

<div class="login-page">
  <div class="card login-container">
    <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
      <div class="card-body login-content">
        <div>
          <a routerLink="/home">
            <img class="login-logo" src="../../../assets/img/parchive-logo.png" alt="Parchive logo">
          </a>
        </div>
        <div class="form-header">
          <h5>Sign into your account</h5>
        </div>
        <div class="form-group">
          <label for="login-email">Email</label>
          <input type="email" id="login-email" placeholder="Email address" formControlName="email">
          <div *ngIf="loginForm.get('email').invalid && 
                                                        (loginForm.get('email').dirty || loginForm.get('email').touched)"
                                                class="error">
            <div *ngIf="loginForm.get('email').errors.required">
                Email is required.
            </div>

            <div *ngIf="loginForm.get('email').errors.email">
              Email is invalid.
            </div>         
          </div>
          
        </div>
        <div>
          <button class="btn-login"
          [disabled]="loginForm.invalid || loading"
          [ngClass]="{'btn-disabled': loginForm.invalid || loading}"
          type="submit">
          <span *ngIf="!loading">Login</span>
          <span *ngIf="loading"><i class="fa fa-circle-notch fa-spin"></i> Logging in...</span>
        </button>
        </div>
        <div class="mt-4 text-center">
          <a [routerLink]="['/', 'signup']" class="text-muted" style="font-weight: 400"><i class="mr-1"></i>Don't have an account? Signup </a>
      </div>
      </div>
    </form>
  </div>
</div>

