import { TransactionHistory } from './transaction-history';

export class TransactionHistoryViewModel {
    bank: string;
    bonus: string;
    carParkName: string;
    cardType: string;
    cashBack: string;
    channel: string;
    creditAmount: string;
    firstName: string;
    id: string;
    lastName: string;
    paymentTransactionId: string;
    reservationAmount: string;
    status: string;
    transactionDate: string;
    transactionID: number;
    type: string;
    userId: string;

    constructor(tranx: TransactionHistory) {
        this.bank = tranx.bank;
        this.bonus = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.bonus);
        this.carParkName = tranx.carParkName;
        this.cardType = tranx.cardType;
        this.cashBack = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.cashBack);
        this.channel = tranx.channel;
        this.creditAmount = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.creditAmount);
        this.firstName = tranx.firstName;
        this.id = tranx.id;
        this.lastName = tranx.lastName;
        this.paymentTransactionId = tranx.paymentTransactionId;
        this.reservationAmount = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(tranx.reservationAmount);
        this.status = tranx.status;
        this.transactionDate = tranx.transactionDate;
        this.transactionID = tranx.transactionID;
        this.type = tranx.type;
        this.userId = tranx.userId;
    }

    getDate() {
        const dateTime = this.transactionDate.split('-');
        return dateTime[0].trim();
    }

    getTime() {
        const dateTime = this.transactionDate.split('-');
        return dateTime[1].trim();
    }
}
