import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-qrcode',
  templateUrl: './my-qrcode.component.html',
  styleUrls: ['./my-qrcode.component.scss']
})
export class MyQrcodeComponent implements OnInit {

  accessId: number;
  passCode: number;
  // userId: string;
  // name: string;

  constructor() { }

  ngOnInit() {
  }

  getQRCodeValue() {
    return `${this.accessId} | ${btoa(this.passCode.toString())}`;
    // return `${this.accessId} | ${this.userId} | ${this.name}`
  }

}
