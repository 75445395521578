import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  loading = false;

  signupForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, this.mobileNumbserValidator()]),
    email: new FormControl('', [Validators.required, Validators.email]),
    acceptTerms: new FormControl('', [Validators.requiredTrue])
  });

  constructor(private authService: AuthenticationService) { }

  ngOnInit() {
  }

  onSignup() {
    let phoneNumber: string = this.signupForm.get('phoneNumber').value;
    phoneNumber = '+234' + phoneNumber.substr(1);

    const firstName: string = this.signupForm.get('firstName').value;
    const lastName: string = this.signupForm.get('lastName').value;
    const email: string = this.signupForm.get('email').value;

    this.loading = true;
    const sub = this.authService.resCompleteAsObservable().subscribe(() => {
      this.loading = false;
      sub.unsubscribe();
    });

    this.authService.onUserInfoSubmitted(firstName, lastName, email, phoneNumber, '');
  }

  mobileNumbserValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = true;

      const mobileNumber = control.value;

      if (isNaN(mobileNumber)) {
        isValid = false;
      }
      else if (mobileNumber.toString().length !== 11) {
        isValid = false;
      }

      return isValid ? null : {phoneNumber: {value: control.value, error: 'Mobile number is invalid'}};
    };
  }

}
