<div class="user-home-container">
  <app-nav-bar [menuColor]="true"></app-nav-bar>
  <div class="content-box large-screen">
    <div class="content-box__text">
      <h1>find&#44; reserve and pay for parking with ease</h1>
    </div>
    <div class="search-destination">
      <form [formGroup]="searchForm">
        <input #userHomeInput id="search-home" type="text" placeholder="Enter destination ..." formControlName="searchText" ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
      </form>
    </div>
    
  </div>


  <div class="mobile-home-container">
      
    <div>
      <h5>Hi, {{username}}</h5>

      <h4 class="search-header">Search for Car park</h4>

      <div>
        <form [formGroup]="searchForm">
          <input class="mobile__places_search_input"
          #userMobileHomeInput id="search-home" type="text" placeholder="Enter destination ..." formControlName="searchText" ngx-google-places-autocomplete [options]='options' 
          #placesRef="ngx-places" (onAddressChange)="handleMobileAddressChange($event)">
      </form>
      </div>
    </div>

    <div (click)="toTransactions()" class="mobile__balance_container" *ngIf="transactionViewmodel.balance">
      <div class="card mobile__balance_card">
        <h6>Available balance:</h6>

        <div class="mobile__balance_amount_container">
          <p class="mobile__balance_amount">{{transactionViewmodel.balance}}</p>
        </div>
        
      </div>
    </div>

    <div class="mobile__quick_menu_container">
      <div class="mobile__quick_menu_flex_container">
        <div class="mobile__quick_menu_flex_item">
          <div (click)="onShowQRCode()" class="card mobile__quick_menu_flex_item_card">
            <span style="color: white;" class="material-icons">
              qr_code_2
              </span>
          </div>
          <div class="quick_menu_title">
            QR Code
          </div>
        </div>
        <div class="mobile__quick_menu_flex_item">
          <div (click)="showAccessCode()" class="card mobile__quick_menu_flex_item_card">
            <span style="color: white;" class="material-icons">
              pin
              </span>
          </div>
          <div class="quick_menu_title">
            Pass Code
          </div>
        </div>
        <div class="mobile__quick_menu_flex_item">
          <div (click)="nearBy()" class="card mobile__quick_menu_flex_item_card">
            <img class="mobile__quick_menu_flex_item_img" src="../../assets/img/ic_radar_near_me_search.png" alt="">
          </div>
          <div class="quick_menu_title">
            Near me
          </div>
        </div>
        <div class="mobile__quick_menu_flex_item">
          <div (click)="showAddVehicle()" class="card mobile__quick_menu_flex_item_card">
            <img class="mobile__quick_menu_flex_item_img" src="../../assets/img/vehicle_sedan_car.png" alt="">
          </div>
          <div class="quick_menu_title">
            Add vehicle
          </div>
        </div>
        
      </div>
    </div>

    <div>
      <div class="mobile__parking_history_title">
        <h6>Parking History</h6>
      </div>
      <div>
        <div *ngFor="let history of parkingHistoryViewModel" class="mobile__parking_history_item_container">
          <div class="mobile__parking_history_item">
            <div>
              <h6 class="mobile__parking_history_item_park_name">{{history.carparkName}}</h6>
              <p class="parking_history_item_park_date">{{history.getBookingDate()}}</p>
            </div>
            <div class="mobile__parking_history_item_amount">
              {{history.getAmountText()}}
            </div>
          </div>
        </div>
         
      </div>
    </div>

  </div>
  
</div>