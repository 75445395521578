<div class="carpark">
  <div class="card">
    <div class="card-body">
      <div class="content">
        <div class="carpark-img">
          <img [src]="carparkInfo.image_urls[0]" alt="">
          <!-- <div class="indicator-container">
          
            <div class="indicator" *ngFor="let img of carparkInfo.image_urls">
              
            </div>
          </div> -->
        </div>
        <div class="carpark-detail">
          <h5>{{carparkInfo.carpark_name}}</h5>
            <div class="carpark-capacity-container">
              <div class="capacity-bk" [ngStyle]="{width: getCapacityProgressWidth()}">
                
              </div>
              <div class="carpark-capacity">
                <div>{{getCapacity()}}%</div>
              </div>
            </div>
          <div class="carpark-detail__icon"><img src="../../../../assets/img/boom_entrance.png" /></div>
          <div class="carpark-detail__price"><span>{{getRateText()}}</span></div>
          <div class="carpark-detail__distance"><span>{{getTimeAwayText()}}</span></div>
          <button (click)="onReserveOrLogin()" class="btn-reserve">{{getButtonText()}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
