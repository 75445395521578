import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pager } from 'src/app/shared/models/pager';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParkingHistoryService {

private BASE_URL = environment.baseUrl;

constructor(private http: HttpClient) { }

getUserParkingHistory(pager: Pager) {
  return this.http.post(this.BASE_URL,
    {
      query: `{ userParkingHistory(page: ${pager.page}) {id booking_date user_id carpark_id carpark_name carpark_address carpark_address plate_number parking_fee parking_lot reservation_date reservation_period validReservation parkingStatus checkInTime checkOutTime amount parkingDuration vehicleType } }`
    }
  );
}

getParkingHistoryCount() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ parkingCount }'
  });
}

}
