import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { ParkingHistoryViewModel } from '../models/parking-history-viewmodel';

@Component({
  selector: 'app-parking-history-detail-modal',
  templateUrl: './parking-history-detail-modal.component.html',
  styleUrls: ['./parking-history-detail-modal.component.scss']
})
export class ParkingHistoryDetailModalComponent implements OnInit {

  history: ParkingHistoryViewModel;
  constructor(private bsModalRef: BsModalRef,
              private dialogService: DialogService) { }

  ngOnInit() {
  }

  getQRCodeValue() {
    return this.history.id;
  }

  close() {
    this.dialogService.getModalSubject().next(null);
    this.bsModalRef.hide();
  }

}
