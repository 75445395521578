import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { DialogService } from '../../services/dialog.service';
import { ToastService } from '../../services/toast.service';
import { Response } from '../../models/response';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-remove-vehicle',
  templateUrl: './remove-vehicle.component.html',
  styleUrls: ['./remove-vehicle.component.scss']
})
export class RemoveVehicleComponent implements OnInit, OnDestroy {

  plateNumber: string;
  category: string;
  private subscription: Subscription;

  constructor(public bsModalRef: BsModalRef,
              private profileService: ProfileService,
              private dialogService: DialogService,
              private toastService: ToastService) { }

  ngOnInit() {
  }

  removeVehicle() {
    this.subscription = this.profileService.removeVehicle(this.plateNumber)
    .subscribe((response: Response<{removeVehicle: boolean}>) => {
      if (response.meta.metaData.statusCode === 200) {
        if (response.data.removeVehicle === true) {
          this.dialogService.getModalSubject().next(true);
          this.toastService.showSuccess('Vehicle removed successfully', 'Success');
          this.bsModalRef.hide();
        } else {
          this.toastService.showError('Error occur while removing vehicle', 'Error');
        }
      } else {
        this.toastService.showError('Error occur while removing vehicle', 'Error');
      }
    }, () => {
      this.toastService.showError('Error occur while removing vehicle', 'Error');
    });
  }

  close() {
    this.dialogService.getModalSubject().next(null);
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
