import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { AuthenticationService } from '../../services/authentication.service';
import { SessionService } from '../../services/session.service';
import { ProfileVm } from '../../../shared/models/profile-vm';
import { Response } from '../../../shared/models/response';
import { User } from '../../../shared/models/user';
import { DialogService } from '../../../shared/services/dialog.service';
import { MyQrcodeComponent } from '../my-qrcode/my-qrcode.component';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { ToastService } from '../../services/toast.service';
import { InviteModalComponent } from '../invite-modal/invite-modal.component';
import { RegisteredVehicle } from '../../models/registered-vehicle';
import { MyAccessCodeComponent } from '../my-access-code/my-access-code.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {

  @Input() hasBkgColor: boolean;
  @Input() menuColor: boolean;
  private subscriptions: Subscription[] = [];
  private profileVm: ProfileVm;
  private passCode: number;
  inviteLink: string;

  navigator = window.navigator as any;


  userInitials = '';

  constructor(private sessionService: SessionService,
              private authenticationService: AuthenticationService,
              private profileService: ProfileService,
              private dialogService: DialogService,
              private toastService: ToastService) { }

  ngOnInit() {
    const userContext = this.sessionService.getUserContext();

    if (!userContext) {
      this.authenticationService.logoutUser();
      return;
    }

    const firstName = userContext.firstName;
    const lastName = userContext.lastName;

    this.userInitials = lastName.substring(0, 1) + firstName.substring(0, 1);
  }

  onShowQRCode() {

    this.subscriptions.push(this.profileService.getUserProfile().subscribe((response: Response<{lookupUser: User}>) => {
      if (response.meta.metaData.statusCode === 200) {

        this.subscriptions.push(this.profileService.getRegisteredVehicle()
        .subscribe((registeredVehicles: Response<{lookupUserVehicles: RegisteredVehicle[]}>) => {
          if (registeredVehicles.meta.metaData.statusCode === 200) {
            if (registeredVehicles.data.lookupUserVehicles.some(x => x.active)) {
              this.subscriptions.push(this.profileService.getPassCode()
              .subscribe((passCode: Response<{generatePasscode: number}>) => {
                if (passCode.meta.metaData.statusCode === 200 ) {
                  this.profileVm = new ProfileVm(response.data.lookupUser);
                  this.passCode = passCode.data.generatePasscode;
                  this.showQRCode();
                } else {
                  this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
                }
              }, () => {
                this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
              }));
            } else {
              this.toastService.showError('You don\'t have an active registered vehicle ', 'Error');
            }
          } else {
            this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
          }
        }, () => {
          this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
        }));

      } else {
        this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
      }
    }, () => {
      this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
    }));
  }

  logOut() {
    this.authenticationService.logoutUser();
  }

  showQRCode() {
    const initialState: any = {
      accessId: this.profileVm.accessId,
      passCode: this.passCode
      // userId: this.profileVm.userId,
      // name: this.profileVm.name
    };

    const config: ModalOptions = { class: 'modal-dialog-centered my-qrcode', initialState };

    this.dialogService.show(MyQrcodeComponent, config);
  }

  onShowAccessCode() {

    this.subscriptions.push(this.profileService.getRegisteredVehicle()
        .subscribe((registeredVehicles: Response<{lookupUserVehicles: RegisteredVehicle[]}>) => {
          if (registeredVehicles.meta.metaData.statusCode === 200) {
            if (registeredVehicles.data.lookupUserVehicles.some(x => x.active)) {

              const config: ModalOptions = { class: 'modal-dialog-centered invite-link', backdrop: 'static' };
              this.dialogService.show(MyAccessCodeComponent, config);

            } else {
              this.toastService.showError('You don\'t have an active registered vehicle ', 'Error');
            }
          } else {
            this.toastService.showError('Couldn\'t display your Access code ', 'Error');
          }
        }, () => {
          this.toastService.showError('Couldn\'t display your Access code ', 'Error');
        }));
  }

  onShare() {

    if (this.inviteLink) {
      this.showInviteLink();
    }
    else {
      this.subscriptions.push(this.profileService.getReferralUrl().subscribe((response: Response<{referralURL: string}>) => {
        if (response.meta.metaData.statusCode === 200) {

          this.inviteLink = response.data.referralURL;
          this.showInviteLink();

        } else {
          this.toastService.showError('Couldn\'t display invite link ', 'Error');
        }
      }, () => {
        this.toastService.showError('Couldn\'t display invite link ', 'Error');
      }));
    }
  }

  showInviteLink() {
    const initialState: any = {
      inviteLink: this.inviteLink
    };

    const config: ModalOptions = { class: 'invite-link', backdrop: 'static', initialState };

    this.dialogService.show(InviteModalComponent, config);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
