import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private BASE_URL = environment.baseUrl;

constructor(private http: HttpClient) { }

getUserProfile() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ lookupUser { phone lastName firstName email user_id dateJoined accessId} }'
  }
  );
}

getReferralUrl() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ referralURL }'
  }
  );
}

getPassCode() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ generatePasscode }'
  }
  );
}

addVehicle(category: string, plateNumber: string) {
  return this.http.post(this.BASE_URL,
    {
      operationName: 'AddVehicleMutation',
      query: 'mutation AddVehicleMutation { addVehicle }',
      variables: {
          category,
          plate_number: plateNumber
      }
  }
  );
}

getRegisteredVehicle() {
  return this.http.post(this.BASE_URL,
    {
      query: '{ lookupUserVehicles {category plateNumber active}  }'
  }
  );
}

removeVehicle(plateNumber: string) {
  return this.http.post(this.BASE_URL,
    {
      operationName: 'RemoveVehicleMutation',
      query: `mutation RemoveVehicleMutation { removeVehicle(plateNumber: \"${plateNumber}\") }`
  }
  );
}

// getReferralClaim(refId: string) {
//   return this.http.post(this.BASE_URL,
//     {
//       query: '{ referralClaiming {total} }',
//       variables: {
//           refId
//       }
//   });
//  }

}
