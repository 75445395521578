import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { SessionService } from '../services/session.service';

@Injectable()
export class NotLoggedInGuard implements CanActivate {
    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private sessionService: SessionService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree |
     Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authenticationService.tokenUserContextExist() === false) {
            this.sessionService.clearSession();
            return true;
        } else {
            this.router.navigateByUrl('/user/home');
        }
    }
}
