<nav class="app-navbar" [ngClass]="{'app-navbar-state': hasBkgColor}">
  <div class="logo-nav">
    <div class="logo">
      <a routerLink="/home"><img *ngIf="!hasBkgColor" src="../../../../assets/img/parchive-logo.png" alt="Parchive logo"></a>
      <a routerLink="/home"><img *ngIf="hasBkgColor" src="../../../../assets/img/Parchive_Logo_White.png" alt="Parchive logo"></a>
    </div>
    <div class="home-nav mr-4">
      <ul class="home-nav__link hide-mobile">
        <li><a [href]="'https://play.google.com/store/apps/details?id=ng.inc.parchive&ah=NCUksgxDi2uwRSw5ViNsptvQJ7A'" target="_blank">Download App</a></li>
        <!-- <li><a [routerLink]="[]">Become a Merchant</a></li> -->
      </ul>
      <div class="btn-group home-nav__menu" dropdown>
        <span id="button-basic" dropdownToggle class="material-icons home-nav__menu__icon mt-2 mr-2" style="cursor: pointer;">menu</span>

        <ul style="font-family: 'Open Sans', sans-serif; font-size: inherit; width: 250px;"  id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
            role="menu" aria-labelledby="button-basic">
          <li role="menuitem"><a class="dropdown-item" [routerLink]="['/signup']">Sign up</a></li>
          <li class="divider dropdown-divider show-mobile"></li>
          <li role="menuitem" class="show-mobile"><a class="dropdown-item" [href]="'https://play.google.com/store/apps/details?id=ng.inc.parchive&ah=NCUksgxDi2uwRSw5ViNsptvQJ7A'" target="_blank">Download App</a></li>
          <!-- <li role="menuitem" class="show-mobile"><a class="dropdown-item" href="#">Become a Merchant</a></li> -->
          <li class="divider dropdown-divider"></li>
          <li role="menuitem"><a class="dropdown-item" [routerLink]="['/login']" >Log in</a>
        </ul>
      </div>
    </div>
  </div>
</nav>
