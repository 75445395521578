<div class="transaction-page">
  <app-nav-bar [hasBkgColor]="true"></app-nav-bar>
  <!-- <div class="transaction-page-container">
    <div class="transaction-content">
      <div class="transaction-balance">
        <div>
          <div class="card" style="background-color:#dcdcdc;">
            <div class="card-body">
              <h3>Balance :</h3>
              <p class="balance_amount">NGN 100000</p>
              <button class="btn-topup">Top Up</button>
            </div>
          </div>
        </div>
      </div>
      <div class="transaction-history">
        <div>
          <div class="card">
            <div class="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First</th>
                    <th scope="col">Last</th>
                    <th scope="col">Handle</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="transaction-page-container">
    <div class="transaction-content">
      <div class="transaction-balance">
        <div class="card" style="background-color:#dcdcdc;">
          <div class="card-body">
            <p class="balance-header">Balance :</p>
            <div class="balance_amount">{{transactionViewmodel.balance}}</div>
            <button class="btn-topup" title="Top up" (click)="onTopUp()">&#43;</button>
          </div>
        </div>
      </div>

      <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()" [scrollWindow]="false">

        <div class="transaction-history">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" ><span style="vertical-align:bottom;" class="material-icons">done</span></th>
                  <th scope="col">Transaction Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Details</th>
                  <th scope="col">Date</th>
                  <th scope="col">Time</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let history of transactionViewmodel.tranxHistory">
                  <ng-container *ngIf="history.type === 'credit'">
                    <tr>
                      <th scope="row"><span class="material-icons credit-icon">double_arrow</span></th>
                      <td>Balance Topup</td>
                      <td>{{history.creditAmount}}</td>
                      <td>In-App Wallet</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="history.type === 'reservation_debit'">
                    <tr>
                      <th scope="row"><span class="material-icons debit-icon">double_arrow</span></th>
                      <td>Car park reservation</td>
                      <td>{{history.reservationAmount}}</td>
                      <td>In-App Wallet</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="history.type === 'expired_reservation'">
                    <tr>
                      <th scope="row"><span class="material-icons cancel-reservation-icon">cancel_presentation</span></th>
                      <td>Cashback on cancellation</td>
                      <td>{{history.cashBack}}</td>
                      <td>{{history.carParkName}}</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="history.type === 'reservation_cashback'">
                    <tr>
                      <th scope="row"><span class="material-icons cash-back-icon">keyboard_capslock</span></th>
                      <td>Cashback on checkout</td>
                      <td>{{history.cashBack}}</td>
                      <td>{{history.carParkName}}</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="history.type === 'bonus'">
                    <tr>
                      <th scope="row"><span class="material-icons bonus-icon">card_giftcard</span></th>
                      <td>Bonus on balance top-up</td>
                      <td>{{history.bonus}}</td>
                      <td>Promotions</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="history.type === 'referral_bonus'">
                    <tr>
                      <th scope="row"><span class="material-icons referral_bonus-icon">card_giftcard</span></th>
                      <td>Bonus on referral</td>
                      <td>{{history.bonus}}</td>
                      <td>Promotions</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="history.type === 'signup_bonus'">
                    <tr>
                      <th scope="row"><span class="material-icons signup_bonus-icon">card_giftcard</span></th>
                      <td>Bonus on sign-up</td>
                      <td>{{history.bonus}}</td>
                      <td>Promotions</td>
                      <td>{{history.getDate()}}</td>
                      <td>{{history.getTime()}}</td>
                    </tr>
                  </ng-container>

                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" style="font-size:10px;" class="text-center">
    <i class="fa fa-circle-notch fa-spin mr-2"></i>Loading...
  </div>
</div>
