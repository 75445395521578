<div class="search-container">
  <app-nav-bar [hasBkgColor]="true"></app-nav-bar>
  <div class="map-container">
    <app-map></app-map>
    <div class="search" [ngClass]="{'search-move-right': hasResult}">
      <input #mapInput (keyup)="clearResult()" placeholder="Search carpark ..." ngx-google-places-autocomplete [options]='options' #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"/>
    </div>
  </div>
  <div [ngClass]="{'search-result-container': hasResult}">
    <div class="d-flex justify-content-end">
      <button class="close-btn" (click)="onResultClose()">&times;</button>
    </div>
    
    <div class="search-result" [ngClass]="{'search-result-one': carparkSearchResult.length === 1}">
      <div *ngFor="let result of carparkSearchResult" class="search-result-item">
        <app-carpark (carparkEvent)="onReserve($event)" [carparkInfo]="result"></app-carpark>
      </div>
    </div>
  </div>
  
</div>
