import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OTPComponent implements OnInit {

  loading = false;

  otpForm = new FormGroup({
    otp: new FormControl('', [Validators.required, this.otpValidator()])
  });

  otpPayload: {[k: string]: any};

  constructor(private authService: AuthenticationService,
              private router: Router) {
                this.otpPayload = this.router.getCurrentNavigation().extras.state;
              }

  ngOnInit() {

    if (!this.otpPayload || !this.otpPayload.type) {
      this.router.navigateByUrl('/login');
      return;
    }
  }

  onVerifyOTP() {

    this.loading = true;

    if (this.otpPayload.type === 'login') {
      const sub = this.authService.resCompleteAsObservable().subscribe(() => {
        this.loading = false;
        sub.unsubscribe();
      });
      this.authService.verifyLoginOTP(this.otpPayload.email, +this.otpForm.get('otp').value);
    }
    else if (this.otpPayload.type === 'signup') {
      const sub = this.authService.resCompleteAsObservable().subscribe(() => {
        this.loading = false;
        sub.unsubscribe();
      });
      this.authService.verifySignUpOTP(this.otpPayload.firstName,
                                        this.otpPayload.lastName,
                                        this.otpPayload.email,
                                        this.otpPayload.phone,
                                        +this.otpForm.get('otp').value,
                                        this.otpPayload.refId);
    }
  }

  onResendOTP() {
    if (this.otpPayload.type === 'login') {
      this.authService.resendLoginOTP(this.otpPayload.email);
    }
    else if (this.otpPayload.type === 'signup') {
      this.authService.resendSignUpOTP(this.otpPayload.email, this.otpPayload.phone);
    }
  }

  otpValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = true;

      const otp = control.value;

      if (isNaN(otp)) {
        isValid = false;
      }
      else if (otp.toString().length !== 6) {
        isValid = false;
      }

      return isValid ? null : {OTP: {value: control.value, error: 'otp is invalid'}};
    };
  }

}
