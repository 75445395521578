import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { OTPComponent } from './auth/otp/otp.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { WebReferralComponent } from './auth/web-referral/web-referral.component';
import { HomeComponent } from './home/home.component';
import { ParkingHistoryComponent } from './parking-history/parking-history.component';
import { ProfileComponent } from './profile/profile.component';
import { ReservationComponent } from './reservation/reservation.component';
import { SearchCarparkComponent } from './search-carpark/search-carpark.component';
import { LoggedInGuard } from './shared/guards/logged-in';
import { NotLoggedInGuard } from './shared/guards/not-logged-in';
import { TopUpSuccessfulComponent } from './shared/widgets/top-up-successful/top-up-successful.component';
import { TransactionComponent } from './transaction/transaction.component';
import { UserHomeComponent } from './user-home/user-home.component';


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'otp',
    component: OTPComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'signup',
    component:  SignUpComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'referral',
    component:  WebReferralComponent,
    canActivate: [NotLoggedInGuard]
  },
  {
    path: 'user/home',
    component:  UserHomeComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'user/search-result',
    component:  SearchCarparkComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'user/transactions',
    component:  TransactionComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'user/parking-history',
    component:  ParkingHistoryComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'user/profile',
    component:  ProfileComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'user/reservation/:id',
    component:  ReservationComponent,
    canActivate: [LoggedInGuard]
  },
  {
    path: 'user/payment/successful',
    component:  TopUpSuccessfulComponent,
    canActivate: [LoggedInGuard]
  },
  {path: '',   redirectTo: '/home', pathMatch: 'full'},
  {path: '**',   redirectTo: '/home', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
