import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ReferralInfo } from 'src/app/shared/models/referralInfo';
import { Response } from '../../shared/models/response';
import { WebReferralService } from './services/web-referral.service';
import { WebReferralModalComponent } from './web-referral-modal/web-referral-modal.component';

@Component({
  selector: 'app-web-referral',
  templateUrl: './web-referral.component.html',
  styleUrls: ['./web-referral.component.scss']
})
export class WebReferralComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  public refId: any;
  referralInfo: ReferralInfo;
  refereeInitials: string;
  subscriptions: Subscription[] = [];

  constructor(private modalService: BsModalService,
              private route: ActivatedRoute,
              private router: Router,
              private referralService: WebReferralService) { }

  ngOnInit() {
    this.refId = this.route.snapshot.queryParams.ref_id;

    if (!this.refId) {
      this.router.navigateByUrl('/home');

      return;
    }

    this.subscriptions.push(this.referralService.getReferralClaim(this.refId)
    .subscribe((response: Response<{referralClaiming: ReferralInfo}>) => {
      if (response.meta.metaData.statusCode === 200) {


        this.referralInfo = response.data.referralClaiming;

        const firstName = this.referralInfo.firstName;
        const lastName = this.referralInfo.lastName;

        this.refereeInitials = lastName.substring(0, 1) + firstName.substring(0, 1);

      } else {
        this.router.navigateByUrl('/home');
      }
    }, error => {
      this.router.navigateByUrl('/home');
    }));
  }

  showRefferalForm() {
    const initialState: Partial<any> = {
      refId: this.refId
    };

    const config: ModalOptions = { class: 'modal-dialog-centered referral', backdrop: 'static', initialState };
    this.modalRef = this.modalService.show(WebReferralModalComponent, config);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
