import { User } from './user';

export class ProfileVm {

    public name: string;
    public email: string;
    public phoneNumber: string;
    public userId: string;
    public accessId: number;
    dateJoined: string;

    constructor(user?: User) {
        this.name = this.setName(user.firstName, user.lastName);
        this.email = user.email;
        this.phoneNumber = this.setPhoneNumber(user.phone);
        this.userId = user.userId;
        this.accessId = user.accessId;
        this.dateJoined = user.dateJoined;
    }

    private setName(firstName: string, lastName: string) {
        return `${lastName} ${firstName}`;
    }

    private setPhoneNumber(phone: string) {

        if (phone.startsWith('+234')) {
           return '0' + phone.substring(4);
        }

        return phone;
    }
}
