<div>
  <app-nav-bar [hasBkgColor]="true"></app-nav-bar>
  <div>
    <div class="page_content">
      <div>
        <h2>Wallet Top-Up Successful!</h2>
      </div>
      <div>
        <p>Your Parchive wallet has been<br /> credited with {{getAmount()}}.</p>
      </div>
      <div>
        <img src="../../../../assets/img/payment_success.png" alt="">
      </div>
    </div>
  </div>

  <div class="dismiss">
    <button class="btn-transaction" (click)="toTransactions()" >Finish</button>
  </div>
</div>


