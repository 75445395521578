import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-up-successful',
  templateUrl: './top-up-successful.component.html',
  styleUrls: ['./top-up-successful.component.scss']
})
export class TopUpSuccessfulComponent implements OnInit {

  paymentReference: string;
  amount: number;

  constructor(private router: Router) {
    const state = this.router.getCurrentNavigation().extras.state;
    this.paymentReference = state ? state.reference : undefined;
    this.amount = state ? state.amount : undefined;
   }

  ngOnInit() {

    if (!this.paymentReference) {
      this.router.navigateByUrl('user/home');

      return;
    }
  }

  getAmount() {
   return new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' })
   .format(this.amount);
  }

  toTransactions() {
    this.router.navigateByUrl('user/transactions');
    return;
  }

}
