<div>
  <div class="modal-header">
  </div>
  <div class="modal-body">
    <div class="qrcode-container">
      <app-qr-code [value]="getQRCodeValue()" [cssClass]="'profile-qrcode'"></app-qr-code>
    </div>
  </div>
</div>


