import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-web-referral-modal',
  templateUrl: './web-referral-modal.component.html',
  styleUrls: ['./web-referral-modal.component.scss']
})
export class WebReferralModalComponent implements OnInit {
  loading = false;
  private refId;
  referralForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, this.mobileNumbserValidator()]),
    email: new FormControl('', [Validators.required, Validators.email]),
    acceptTerms: new FormControl('', [Validators.requiredTrue])
  });

  constructor(public bsModalRef: BsModalRef,
              private authService: AuthenticationService) { }

  ngOnInit() {
  }

  mobileNumbserValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = true;

      const mobileNumber = control.value;

      if (isNaN(mobileNumber)) {
        isValid = false;
      } else if (mobileNumber.toString().length !== 11) {
        isValid = false;
      }

      return isValid ? null : { phoneNumber: { value: control.value, error: 'Mobile number is invalid' } };
    };
  }

  onSignup() {
    let phoneNumber: string = this.referralForm.get('phoneNumber').value;
    phoneNumber = '+234' + phoneNumber.substr(1);

    const firstName: string = this.referralForm.get('firstName').value;
    const lastName: string = this.referralForm.get('lastName').value;
    const email: string = this.referralForm.get('email').value;

    this.loading = true;
    const sub = this.authService.resCompleteAsObservable().subscribe(() => {
      this.loading = false;
      sub.unsubscribe();
    });

    this.authService.onUserInfoSubmitted(firstName, lastName, email, phoneNumber, this.refId);
    this.bsModalRef.hide();
  }
}
