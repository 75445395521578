<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="rate-content">
      <div>
        <div *ngIf="hasDayWeekId(1)">
          <div class="week-day">Monday - Friday</div>
          <div *ngFor="let rate of getDayWeekParkingRates(1)">
            <div class="rate_item_container">
              <div class="img_des">
                <div class="vehicle_type" *ngIf="showVehicleType(rate)">
                  <img [src]="getVehicleImagePath(rate)" alt="" [width]="24">
                </div>
                <div class="rate_description">
                  {{getMONFRIDesciptionText(rate)}}
                </div>
              </div>
              <div class="rate_amount">
                {{getMONFRIAmountText(rate)}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="hasDayWeekId(2)">
          <div class="week-day">Saturday</div>
          <div *ngFor="let rate of getDayWeekParkingRates(2)">
            <div class="rate_item_container">
              <div class="img_des">
                <div class="vehicle_type" *ngIf="showVehicleType(rate)">
                  <img [src]="getVehicleImagePath(rate)" alt="" [width]="24">
                </div>
                <div class="rate_description">
                  {{getMONFRIDesciptionText(rate)}}
                </div>
              </div>
              <div class="rate_amount">
                {{getMONFRIAmountText(rate)}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="hasDayWeekId(3)">
          <div class="week-day">Sunday</div>
          <div *ngFor="let rate of getDayWeekParkingRates(3)">
            <div class="rate_item_container">
              <div class="img_des">
                <div class="vehicle_type" *ngIf="showVehicleType(rate)">
                  <img [src]="getVehicleImagePath(rate)" alt="" [width]="24">
                </div>
                <div class="rate_description">
                  {{getSUNDesciptionText(rate)}}
                </div>
              </div>
              <div class="rate_amount">
                {{getSUNAmountText(rate)}}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="hasDayWeekId(4)">
          <div class="week-day">Over Night</div>
          <div *ngFor="let rate of getDayWeekParkingRates(4)">
            <div class="rate_item_container">
              <div class="img_des">
                <div class="vehicle_type" *ngIf="showVehicleType(rate)">
                  <img [src]="getVehicleImagePath(rate)" alt="" [width]="24">
                </div>
                <div class="rate_description">
                  {{getSUNDesciptionText(rate)}}
                </div>
              </div>
              <div class="rate_amount">
                &#8358;{{getSUNAmountText(rate)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

