import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-intro-video-modal',
  templateUrl: './intro-video-modal.component.html',
  styleUrls: ['./intro-video-modal.component.scss']
})
export class IntroVideoModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
