import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ProfileService } from '../profile/services/profile.service';
import { ProfileVm } from '../shared/models/profile-vm';
import { RegisteredVehicle } from '../shared/models/registered-vehicle';
import { User } from '../shared/models/user';
import { DialogService } from '../shared/services/dialog.service';
import { ToastService } from '../shared/services/toast.service';
import { MyQrcodeComponent } from '../shared/widgets/my-qrcode/my-qrcode.component';
import {Response} from '../shared/models/response';
import { MyAccessCodeComponent } from '../shared/widgets/my-access-code/my-access-code.component';
import { AddVehicleModalComponent } from '../shared/widgets/add-vehicle-modal/add-vehicle-modal.component';
import { ParkingHistoryService } from '../parking-history/services/parking-history.service';
import { ParkingHistory } from '../parking-history/models/parking-history';
import { ParkingHistoryViewModel } from '../parking-history/models/parking-history-viewmodel';
import { Pager } from '../shared/models/pager';
import { UserContext } from '../shared/models/user-context';
import { SessionService } from '../shared/services/session.service';
import { TransactionService } from '../transaction/services/transaction.service';
import { TransactionViewModel } from '../shared/models/transaction-viewmodel';

@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit, OnDestroy {

  @ViewChild('userHomeInput') userHomeInput: ElementRef;
  @ViewChild('userMobileHomeInput') userMobileHomeInput: ElementRef;

  private subscriptions: Subscription[] = [];
  parkingHistoryViewModel: ParkingHistoryViewModel[] = [];
  transactionViewmodel: TransactionViewModel = new TransactionViewModel();
  private profileVm: ProfileVm;
  private passCode: number;
  private pager: Pager = {page: 1, pageSize: 3};
  username: string;

  searchForm = new FormGroup({
    searchText: new FormControl('', [Validators.required])
  });

  options = {
    componentRestrictions: {
      country: ['NG']
    },
    fields: ['place_id', 'geometry', 'formatted_address']
  };

  constructor(private router: Router,
              private profileService: ProfileService,
              private dialogService: DialogService,
              private parkingHistoryService: ParkingHistoryService,
              private toastService: ToastService,
              private transactionService: TransactionService,
              private sessionService: SessionService) { }

  ngOnInit() {
    this.subscriptions.push(this.parkingHistoryService.getUserParkingHistory(this.pager)
    .subscribe((response: Response<{userParkingHistory: ParkingHistory []}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.parkingHistoryViewModel = response.data.userParkingHistory.slice(0, 3).map((x) => {
          const parkingHistory = new ParkingHistoryViewModel(x);
          return parkingHistory;
        });
    }}));

    this.username = this.sessionService.getUserContext().firstName;

    this.subscriptions.push(this.transactionService.getUserBalance().subscribe((response: Response<{balance: number}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.transactionViewmodel.balance = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' })
        .format(response.data.balance / 100);
      }
    }));
  }

  onSearch(latitude, longitude, searchAddress, searchCategory) {
    const searchPayload = { latitude, longitude, searchAddress, searchCategory };
    this.router.navigateByUrl('/user/search-result', {state: {searchPayload}});
  }

  public handleAddressChange(address: any) {
      const latitude = address.geometry.location.lat();
      const longitude = address.geometry.location.lng();
      const searchAddress = (this.userHomeInput.nativeElement as HTMLInputElement).value;

      this.onSearch(latitude, longitude, searchAddress, 'destination');

  }

  public handleMobileAddressChange(address: any) {
    const latitude = address.geometry.location.lat();
    const longitude = address.geometry.location.lng();
    const searchAddress = (this.userMobileHomeInput.nativeElement as HTMLInputElement).value;

    this.onSearch(latitude, longitude, searchAddress, 'destination');

}

onShowQRCode() {

  this.subscriptions.push(this.profileService.getUserProfile().subscribe((response: Response<{lookupUser: User}>) => {
    if (response.meta.metaData.statusCode === 200) {

      this.subscriptions.push(this.profileService.getRegisteredVehicle()
      .subscribe((registeredVehicles: Response<{lookupUserVehicles: RegisteredVehicle[]}>) => {
        if (registeredVehicles.meta.metaData.statusCode === 200) {
          if (registeredVehicles.data.lookupUserVehicles.some(x => x.active)) {
            this.subscriptions.push(this.profileService.getPassCode()
            .subscribe((passCode: Response<{generatePasscode: number}>) => {
              if (passCode.meta.metaData.statusCode === 200 ) {
                this.profileVm = new ProfileVm(response.data.lookupUser);
                this.passCode = passCode.data.generatePasscode;
                this.showQRCode();
              } else {
                this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
              }
            }, () => {
              this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
            }));
          } else {
            this.toastService.showError('You don\'t have an active registered vehicle ', 'Error');
          }
        } else {
          this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
        }
      }, () => {
        this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
      }));

    } else {
      this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
    }
  }, () => {
    this.toastService.showError('Couldn\'t display your QRcode ', 'Error');
  }));
}

  showQRCode() {
    const initialState: any = {
      accessId: this.profileVm.accessId,
      passCode: this.passCode
      // userId: this.profileVm.userId,
      // name: this.profileVm.name
    };

    const config: ModalOptions = { class: 'modal-dialog-centered my-qrcode', initialState };

    this.dialogService.show(MyQrcodeComponent, config);
  }

  showAddVehicle() {
    const config: ModalOptions = { class: 'modal-dialog-centered add-vehicle', backdrop: 'static' };

    this.dialogService.show(AddVehicleModalComponent, config);

    const sub = this.dialogService.modalSubjectAsObservable()
    .subscribe((addedStatus: boolean) => {
      sub.unsubscribe();
    });
  }

  showAccessCode() {
    this.subscriptions.push(this.profileService.getRegisteredVehicle()
        .subscribe((registeredVehicles: Response<{lookupUserVehicles: RegisteredVehicle[]}>) => {
          if (registeredVehicles.meta.metaData.statusCode === 200) {
            if (registeredVehicles.data.lookupUserVehicles.some(x => x.active)) {

              const config: ModalOptions = { class: 'modal-dialog-centered invite-link', backdrop: 'static' };
              this.dialogService.show(MyAccessCodeComponent, config);

            } else {
              this.toastService.showError('You don\'t have an active registered vehicle ', 'Error');
            }
          } else {
            this.toastService.showError('Couldn\'t display your Access code ', 'Error');
          }
        }, () => {
          this.toastService.showError('Couldn\'t display your Access code ', 'Error');
        }));
  }

  nearBy() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        this.onSearch(lat, lng, '', 'proximity');
      });
    } else {}
  }

  toTransactions() {
    this.router.navigateByUrl('/user/transactions');
    return;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
