<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="code_description_container">
      <p class="code-description">Kindly use the generated code below at point of entry/exit</p>
    </div>
    <div class="code_container">
      <p class="access_code">{{passCode}}</p>
    </div>
    <div class="timer_progress_container">
      <circle-progress
      [percent] = "progress"
      [radius] = "100"
      [space] = "-10"
      [toFixed] = "0"
      [maxPercent] = "100"
      [unitsFontSize] = "'16'"
      [outerStrokeGradient] = "true"
      [outerStrokeWidth] = "'10'"
      [outerStrokeColor]="'#003eff'"
      [outerStrokeGradientStopColor] = "'#003eff'"
      [innerStrokeColor]= "'#e7e8ea'"
      [innerStrokeWidth] = "10"
      [title] = "timer"
      [units] ="'s'"
      [titleFontSize] ="32"
      [animateTitle]= "false"
      [animationDuration] = "1000"
      [animation] = "false"
      [showSubtitle] = "false"
      [showBackground] = "false"
      [startFromZero] ="false"
      [renderOnClick] = "false"
      [lazy]="true"
      ></circle-progress>
    </div>
    <div class="gen_btn_container" *ngIf="showGenAccess">
      <button class="btn-access_code" (click)="onGenerateAccessCode()">Generate code for me</button>
    </div>
  </div>
</div>
