import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarparkService {

  GET_NEAREST_PARK_BASE_URL = environment.nearbyParkBaseUrl;
  GET_BASE_URL = environment.baseUrl;

constructor(private http: HttpClient) { }

getNearestCarparksByUnknown(latitude: number, longitude: number) {
 return this.http.get(`${this.GET_NEAREST_PARK_BASE_URL}/${latitude}/${longitude}/unknown/destination`);
}

getNearestCarparks(latitude: number, longitude: number, searchCategory: string) {
  return this.http.post(this.GET_BASE_URL,
    {
      query: '{ nearbyCarparks {carpark_id carpark_name commission activated verified carpark_address parking_lots{status label} distance capacity contactEmail contactPhoneNumber parkingRates{amount dayOfWeekId description maxHour minHour vehicleType extra_hour_amount} latitude longitude country city feeType image_urls flatFeeAmount amountPerHour closing_time opening_time dateCreated }  }',
      variables: {
          latitude,
          longitude,
          search_category: searchCategory
      }
  });
 }
}
