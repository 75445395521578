export const environment = {
  production: true,
  baseUrl: 'https://cloudservice.parchive.space/parchive/web/api/v1/graphql',
  baseUrl2: 'https://cloudservice.parchive.space/api/v1/parchive/inc/graphql',
  nearbyParkBaseUrl: 'https://cloudservice.parchive.space/api/v1/nearby',
  paystackPublicKey: 'pk_live_93c54a8edbfaa134fe5293a86abb7c11b1137e54',
  mapboxAccessToken: 'pk.eyJ1IjoicGFyY2hpdmUtdGVjaG5vbG9naWVzIiwiYSI6ImNqdjJrd2tlbzB0aHU0NHA5eWE5aDgzbHAifQ.u4g3B6l-zhaGJIoKetupOg',
  'carpak-images': {
    image1: 'https://res.cloudinary.com/parchive-technologies/image/upload/v1604354272/carparks/FCM_IM1.jpg',
    image2: 'https://res.cloudinary.com/parchive-technologies/image/upload/v1608074873/carparks/tcc_image_one.jpg',
    image3: 'https://res.cloudinary.com/parchive-technologies/image/upload/v1600799650/carparks/Antenna_IM1.jpg'
  }
};
