import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NavBarComponent } from './widgets/nav-bar/nav-bar.component';
import { CarparkComponent } from './widgets/carpark/carpark.component';
import { MapComponent } from './widgets/map/map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WebReferralNavBarComponent } from './widgets/web-referral-nav-bar/web-referral-nav-bar.component';
import { AppRoutingModule } from '../app-routing.module';
import { TokenInterceptorService } from './token-interceptor.service';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { QrCodeComponent } from './widgets/qr-code/qr-code.component';
import { AddVehicleModalComponent } from './widgets/add-vehicle-modal/add-vehicle-modal.component';
import { RemoveVehicleComponent } from './widgets/remove-vehicle/remove-vehicle.component';
import { MyQrcodeComponent } from './widgets/my-qrcode/my-qrcode.component';
import { InviteModalComponent } from './widgets/invite-modal/invite-modal.component';
import { CarparkRatesComponent } from './widgets/carpark-rates/carpark-rates.component';
import { MyAccessCodeComponent } from './widgets/my-access-code/my-access-code.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { TopUpSuccessfulComponent } from './widgets/top-up-successful/top-up-successful.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxQRCodeModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BsDropdownModule.forRoot(),
    NgCircleProgressModule.forRoot()
  ],
  declarations: [NavBarComponent,
                CarparkComponent,
                MapComponent,
                WebReferralNavBarComponent,
                QrCodeComponent,
                AddVehicleModalComponent,
                RemoveVehicleComponent,
                MyQrcodeComponent,
                InviteModalComponent,
                CarparkRatesComponent,
                MyAccessCodeComponent,
                TopUpSuccessfulComponent
              ],
  exports: [NavBarComponent,
            CarparkComponent,
            MapComponent,
            WebReferralNavBarComponent,
            QrCodeComponent,
            AddVehicleModalComponent,
            RemoveVehicleComponent,
            MyQrcodeComponent,
            InviteModalComponent,
            CarparkRatesComponent,
            MyAccessCodeComponent,
            TopUpSuccessfulComponent
          ],
  providers: [{provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true}]
})
export class SharedModule { }
