import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Response } from '../models/response';
import { ToastService } from './toast.service';
import { UserContext } from '../models/user-context';
import { VerifyOtp } from '../models/verify-otp';
import { AppConstants } from '../models/app-constants';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private BASE_URL = environment.baseUrl;
  private BASE_URL2 = environment.baseUrl2;
  responseCompleteSubject = new Subject<boolean>();

constructor(private sessionService: SessionService,
            private http: HttpClient,
            private router: Router,
            private toastService: ToastService) { }

loginUser(email: string) {
  this.http.post(this.BASE_URL,
    {
      query: `{ login(email: \"${email}\") }`
  }).subscribe((response: Response<{ login: boolean }>) => {
    if (response.meta.metaData.statusCode === 200) {

      if (response.data.login) {

        const otpPayload = { type: 'login', email };

        this.responseCompleteSubject.next(true);
        this.router.navigateByUrl('/otp', { state: otpPayload });

      } else {
        this.responseCompleteSubject.next(true);
        this.toastService.showError('User does not exist', 'Error');
      }
    } else {
      this.responseCompleteSubject.next(true);
      this.toastService.showError('User does not exist', 'Error');
    }
  }
  , (err) => {
    this.responseCompleteSubject.next(true);
    this.toastService.showError('Error occured while login you in', 'Error');
  }
  );
}

verifyLoginOTP(email: string, otp: number) {
  this.http.post(this.BASE_URL,
    {
      operationName: 'VerifyOTPMutation',
      query: 'mutation VerifyOTPMutation { verifyOTP{user_id firstName lastName phone email token} }',
      variables: {
          email,
          otp
      }
  }).subscribe((response: Response<{ verifyOTP: VerifyOtp }>) => {
    if (response.meta.metaData.statusCode === 200) {

      const userContext: UserContext = {
        firstName: response.data.verifyOTP.firstName,
        lastName: response.data.verifyOTP.lastName,
        email: response.data.verifyOTP.email,
        phoneNumber: response.data.verifyOTP.phoneNumber
      };

      this.sessionService.setUserContext(userContext);
      this.sessionService.setAccessToken(response.data.verifyOTP.token);
      this.responseCompleteSubject.next(true);

      this.toHomeOrSearchResult();
      this.updateLastSeen(response.data.verifyOTP.token);
    } else {
      this.responseCompleteSubject.next(true);
      this.toastService.showError('Error occured while verifying otp', 'Error');
    }
  }, () => {
    this.responseCompleteSubject.next(true);
    this.toastService.showError('Error occured while verifying otp', 'Error');
  });
}

verifySignUpOTP(firstName: string, lastName: string, email: string, phone: string, otp: number, refId: string) {

  this.http.post(this.BASE_URL2,
    {
      operationName: 'ValidateUserOtpMutation',
      query: `mutation ValidateUserOtpMutation { validateUserOtp(phone_or_email: \"${email}\", otp: ${otp}) }`
  }).subscribe((response: Response<{ validateUserOtp: boolean }>) => {
    if (response.meta.metaData.statusCode === 200) {

      if (response.data.validateUserOtp) {
        this.signUpUser(firstName, lastName, email, phone, refId);
      }
      else
      {
        this.responseCompleteSubject.next(true);
        this.toastService.showError('Error occured while verifying account', 'Error');
      }
    } else {
      this.responseCompleteSubject.next(true);
      this.toastService.showError('Error occured while verifying account', 'Error');
    }
  }, () => {
    this.responseCompleteSubject.next(true);
    this.toastService.showError('Error occured while verifying account', 'Error');
  });
}

resendLoginOTP(email: string) {
  this.http.post(this.BASE_URL,
    {
      query: `{ login(email: \"${email}\") }`
  }).subscribe((response: Response<{ login: boolean }>) => {
    if (response.meta.metaData.statusCode === 200) {

      if (response.data.login) {
        this.toastService.showSuccess('Verification code has been sent', 'Success');
      } else {
        this.toastService.showError('Error occured while sending verification code', 'Error');
      }
    } else {
      this.toastService.showError('Error occured while sending verification code', 'Error');
    }
  }, () => {
    this.toastService.showError('Error occured while sending verification code', 'Error');
  });
}

resendSignUpOTP(email: string, phone: string) {
  this.http.post(this.BASE_URL,
    {
      query: `{ verifyNewUser(email: \"${email}\", phone: \"${phone}\") }`
  }).subscribe((response: Response<{ verifyNewUser: boolean}>) => {
    if (response.meta.metaData.statusCode === 200) {

      if (response.data.verifyNewUser) {

        this.toastService.showSuccess('Verification code has been sent', 'Success');

      } else {
        this.toastService.showError('Error occured while sending verification code', 'Error');
      }
    } else {
      this.toastService.showError('Error occured while sending verification code', 'Error');
    }
  }, () => {
    this.toastService.showError('Error occured while sending verification code', 'Error');
  });
}


onUserInfoSubmitted(firstName: string, lastName: string, email: string, phone: string, refId: string) {
  this.verifyNewUser(firstName, lastName, email, phone, refId);
}

signUpUser(firstName: string, lastName: string, email: string, phone: string, refId: string) {
 this.http.post(this.BASE_URL,
  {
    operationName: 'SignUpMutation',
    query: 'mutation SignUpMutation { signup }',
    variables: {
        phone,
        email,
        firstName,
        lastName,
        refId
    }
}).subscribe((response: Response<{ signup: string }>) => {
  if (response.meta.metaData.statusCode === 200) {

    const userContext: UserContext = {
      firstName,
      lastName,
      email,
      phoneNumber: phone
    };

    this.sessionService.setUserContext(userContext);
    this.sessionService.setAccessToken(response.data.signup);

    this.toastService.showSuccess('Account created successfully', 'Success');
    // this.router.navigateByUrl('/user/home');
    this.responseCompleteSubject.next(true);
    this.toHomeOrSearchResult();
    this.updateLastSeen(response.data.signup);
  } else {
    this.responseCompleteSubject.next(true);
    this.toastService.showError('Error occured while creating your account', 'Error');
  }
}, () => {
  this.responseCompleteSubject.next(true);
  this.toastService.showError('Error occured while creating your account', 'Error');
});
}

verifyNewUser(firstName: string, lastName: string, email: string, phone: string, refId: string) {
  this.http.post(this.BASE_URL,
    {
      query: `{ verifyNewUser(email: \"${email}\", phone: \"${phone}\") }`
  }).subscribe((response: Response<{ verifyNewUser: boolean}>) => {
    if (response.meta.metaData.statusCode === 200) {

      if (response.data.verifyNewUser) {

        this.responseCompleteSubject.next(true);
        const otpPayload = { type: 'signup', firstName, lastName, email, phone, refId };
        this.router.navigateByUrl('/otp', { state: otpPayload });

      } else {
        this.responseCompleteSubject.next(true);
        this.toastService.showError('Error occured while creating your account', 'Error');
      }
    } else {
      this.responseCompleteSubject.next(true);
      this.toastService.showError('Error occured while creating your account', 'Error');
    }
  }, () => {
    this.responseCompleteSubject.next(true);
    this.toastService.showError('Error occured while creating your account', 'Error');
  });
}

logoutUser(){
  this.sessionService.clearSession();
  window.localStorage.removeItem(AppConstants.PWCUSERSEARCHKEY);
  this.toastService.showSuccess('Logout successsful');
  this.router.navigateByUrl('/login');
}

isAuthenticated() {

  const token = this.sessionService.getAccessToken();
  const isExpired = this.sessionService.isTokenExpired(token);

  return !isExpired;
}

tokenUserContextExist() {
  const token = this.sessionService.getAccessToken();
  const userContext = this.sessionService.getUserContext();

  if (token && userContext) {
    return true;
  } else {
    return false;
  }
}

toHomeOrSearchResult() {
  const result =  window.localStorage.getItem(AppConstants.PWCUSERSEARCHKEY);

  if (result) {
    const searchCoord: {longitude: string, latitude: string, searchAddress: string} = JSON.parse(result);

    const searchPayload = {
      latitude: searchCoord.latitude,
      longitude: searchCoord.longitude,
      searchAddress: searchCoord.searchAddress
    };

    window.localStorage.removeItem(AppConstants.PWCUSERSEARCHKEY);

    this.router.navigateByUrl('/user/search-result', {state: {searchPayload}});
  } else {
    this.router.navigateByUrl('/user/home');
  }
}

refreshToken(token: string) {
  return this.http.post(this.BASE_URL,
    {
      query: '{ refreshToken }'
  });
}

updateLastSeen(token: string) {
  this.http.post(this.BASE_URL,
    {
      operationName: 'UpdateLastSeenMutation',
      query: 'mutation UpdateLastSeenMutation { updateLastSeen }',
    },
  {
      headers: new HttpHeaders({token})
  }).subscribe((response: Response<{updateLastSeen: boolean}>) => {

  });
}

resCompleteAsObservable() {
  return this.responseCompleteSubject.asObservable();
}
}
