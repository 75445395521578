import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { Pager } from '../shared/models/pager';
import { Response } from '../shared/models/response';
import { DialogService } from '../shared/services/dialog.service';
import { ParkingHistory } from './models/parking-history';
import { ParkingHistoryViewModel } from './models/parking-history-viewmodel';
import { ParkingHistoryDetailModalComponent } from './parking-history-detail-modal/parking-history-detail-modal.component';
import { ParkingHistoryService } from './services/parking-history.service';

@Component({
  selector: 'app-parking-history',
  templateUrl: './parking-history.component.html',
  styleUrls: ['./parking-history.component.scss']
})
export class ParkingHistoryComponent implements OnInit, OnDestroy {

  private pager: Pager = {page: 1, pageSize: 20};

  parkingHistoryViewModel: ParkingHistoryViewModel[];
  totalCount: number;

  loading = false;
  parkingHistorySubscriptions: Subscription[] = [];

  constructor(private parkingHistoryService: ParkingHistoryService,
              private dialogService: DialogService) { }

  ngOnInit() {
    this.parkingHistorySubscriptions.push(this.parkingHistoryService.getUserParkingHistory(this.pager)
    .subscribe((response: Response<{userParkingHistory: ParkingHistory []}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.parkingHistoryViewModel = response.data.userParkingHistory.map((x) => {
          const parkingHistory = new ParkingHistoryViewModel(x);
          return parkingHistory;
        });
    }}));

    this.parkingHistorySubscriptions.push(this.parkingHistoryService.getParkingHistoryCount()
    .subscribe((response: Response<{parkingCount: number}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.totalCount = response.data.parkingCount;
      }
    }));
  }

  onHistoryClicked(history: ParkingHistoryViewModel) {

    const initialState: any = {
      history
    };

    const config: ModalOptions = { class: 'modal-dialog-centered parking-detail', backdrop: 'static', initialState };
    this.dialogService.show(ParkingHistoryDetailModalComponent, config);
  }

  onScroll() {
    if (this.totalCount > this.parkingHistoryViewModel.length && this.loading === false) {
      this.loading = true;

      setTimeout(() => {
        ++this.pager.page;
        this.parkingHistorySubscriptions.push(this.parkingHistoryService.getUserParkingHistory(this.pager)
      .subscribe((response: Response<{userParkingHistory: ParkingHistory []}>) => {
        if (response.meta.metaData.statusCode === 200) {
          const oldItem = this.parkingHistoryViewModel;
          const newItem = response.data.userParkingHistory.map((x) => {
            const parkingHistory = new ParkingHistoryViewModel(x);
            return parkingHistory;
          });
          this.parkingHistoryViewModel = [...oldItem.concat(newItem)];
          this.loading = false;
        }
        else {
          this.loading = false;
        }
      }, () => {
        this.loading = false;
      }));
      }, 5000);
    }
  }

  ngOnDestroy() {
    this.parkingHistorySubscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
