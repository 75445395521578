import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pager } from '../shared/models/pager';
import { Response } from '../shared/models/response';
import { TransactionHistory } from '../shared/models/transaction-history';
import { TransactionHistoryViewModel } from '../shared/models/transaction-history-viewmodel';
import { TransactionViewModel } from '../shared/models/transaction-viewmodel';
import { AuthenticationService } from '../shared/services/authentication.service';
import { SessionService } from '../shared/services/session.service';
import { ToastService } from '../shared/services/toast.service';
import { Charges } from './models/charges';
import { PaymentCollectionModalComponent } from './payment-collection-modal/payment-collection-modal.component';
import { TransactionService } from './services/transaction.service';

interface MyWindow extends Window {
  PaystackPop: any;
}
declare var window: MyWindow;

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss']
})
export class TransactionComponent implements OnInit, OnDestroy {

  private pager: Pager = {page: 1, pageSize: 20};
  transactionViewmodel: TransactionViewModel;
  loading = false;
  transactionSubscriptions: Subscription[] = [];

  constructor(private transactionService: TransactionService,
              private modalService: BsModalService,
              private sessionService: SessionService,
              private authService: AuthenticationService,
              public toastService: ToastService,
              private router: Router,
              private ngZone: NgZone) { }

  ngOnInit() {
    this.transactionViewmodel = new TransactionViewModel();
    this.transactionSubscriptions.push(this.transactionService.getUserTransactions(this.pager)
    .subscribe((response: Response<{transactions: TransactionHistory[]}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.transactionViewmodel.tranxHistory = response.data.transactions.map((x) => {
          const transaction = new TransactionHistoryViewModel(x);
          return transaction;
        });
      }

    }));

    this.transactionSubscriptions.push(this.transactionService.getUserBalance().subscribe((response: Response<{balance: number}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.transactionViewmodel.balance = new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' })
        .format(response.data.balance / 100);
      }
    }));

    this.transactionSubscriptions.push(this.transactionService.getTransactionCount()
    .subscribe((response: Response<{transactionCount: number}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.transactionViewmodel.totalTranxCount = response.data.transactionCount;
      }
    }));
  }

  onTopUp() {
    const usercontext = this.sessionService.getUserContext();
    if (!usercontext || !usercontext.email || !usercontext.lastName || !usercontext.firstName ) {
      this.authService.logoutUser();
      return;
    }

    this.transactionSubscriptions.push(this.transactionService.getCharges().subscribe((response: Response<{charges: Charges}>) => {
      if (response.meta.metaData.statusCode === 200) {
        const initialState: any = {
          paymentGatewayPercentage: response.data.charges.paymentGatewayPercentage,
          paymentGatewayTransaction: response.data.charges.paymentGatewayTransactionCharge_NGN,
          transactionFeePercentage: response.data.charges.transactionFeePercentage,
          firstName: usercontext.firstName,
          lastName: usercontext.lastName,
          email: usercontext.email
        };

        const sub = this.transactionService.paymentSubjectAsObservable().subscribe((content) => {
          sub.unsubscribe();
          this.topUpWithPaystack(content.firstName, content.lastName, content.email, content.amount, content.total);
        });

        const config: ModalOptions = { class: 'modal-dialog-centered referral', backdrop: 'static', initialState };
        const modal = this.modalService.show(PaymentCollectionModalComponent, config);
      } else {
        this.toastService.showError('Could not initiate transaction');
      }

    }, () => {
      this.toastService.showError('Error occurred while initiating transaction');
    }));

  }

  topUpWithPaystack(firstname: string, lastname: string, email: string, amount: number, total: number) {
    this.transactionSubscriptions.push(this.transactionService.getPaymentReference(+amount, total)
    .subscribe((response: Response<{balanceTopupPaymentReference: string}>) => {
      if (response.meta.metaData.statusCode === 200) {
        const handler = window.PaystackPop.setup({
          key: environment.paystackPublicKey, // 'pk_test_63d5a09d269ee1abc780dd446702bff67064d64d', // Replace with your public key
          email,
          amount: parseInt((total * 100).toString(), 10),
          firstname,
          ref: response.data.balanceTopupPaymentReference,
          lastname,
          channels: ['card', 'bank'],
          onClose: () => {
          },
          callback: (response1) => {
            if (response.data.balanceTopupPaymentReference === response1.reference ) {
              this.ngZone.run(() => {
                this.router.navigateByUrl('user/payment/successful',
                {state: {reference: response.data.balanceTopupPaymentReference, amount}});
              });
            }
            // this.transactionSubscriptions.push(this.transactionService.verifyPayment(response1.reference, amount * 100)
            // .subscribe((chargeResponse: Response<{creditUser: boolean}>) => {
            //   // location.href = "http://localhost:4200/user/transactions";
            //   if (chargeResponse.meta.metaData.statusCode === 200 && chargeResponse.data.creditUser) {
            //     this.toastService.showSuccess('Payment recieved. Refresh page for updated history', 'Successful top-up');
            //   }
            //   else {
            //     this.toastService.showError('Could not verify payment', 'Unsuccessful top-up');
            //   }
            // }, () => {
            //   this.toastService.showError('Could not verify payment', 'Unsuccessful top-up');
            // }));
          }
        });
        handler.openIframe();
      }else {
        this.toastService.showError('Could not initiate payment', 'Unsuccessful top-up');
      }

    }, () => {
      this.toastService.showError('Could not initiate payment', 'Unsuccessful top-up');
    }));
    // const handler = window.PaystackPop.setup({
    //   key: environment.paystackPublicKey, // 'pk_test_63d5a09d269ee1abc780dd446702bff67064d64d', // Replace with your public key
    //   email,
    //   amount: parseInt((total * 100).toString(), 10),
    //   firstname,
    //   lastname,
    //   channels: ['card'],
    //   onClose: () => {
    //   },
    //   callback: (response) => {

    //     this.transactionSubscriptions.push(this.transactionService.verifyPayment(response.reference, amount * 100)
    //     .subscribe((chargeResponse: Response<{creditUser: boolean}>) => {
    //       // location.href = "http://localhost:4200/user/transactions";
    //       if (chargeResponse.meta.metaData.statusCode === 200 && chargeResponse.data.creditUser) {
    //         this.toastService.showSuccess('Payment recieved. Refresh page for updated history', 'Successful top-up');
    //       }
    //       else {
    //         this.toastService.showError('Could not verify payment', 'Unsuccessful top-up');
    //       }
    //     }, () => {
    //       this.toastService.showError('Could not verify payment', 'Unsuccessful top-up');
    //     }));
    //   }
    // });
    // handler.openIframe();
  }

  onScroll() {
    if (this.transactionViewmodel.totalTranxCount > this.transactionViewmodel.tranxHistory.length && this.loading === false) {
      this.loading = true;
      // this.ngxSpinner.show('primary', {
      //   type: 'square-spin',
      //   size: 'small',
      //   bdColor: 'rgba(100,149,237, .8)',
      //   color: 'white',
      //   fullScreen: false
      // });
      setTimeout(() => {
        ++this.pager.page;
        this.transactionSubscriptions.push(this.transactionService.getUserTransactions(this.pager)
      .subscribe((response: Response<{transactions: TransactionHistory[]}>) => {
        if (response.meta.metaData.statusCode === 200) {
          const oldItem = this.transactionViewmodel.tranxHistory;
          const newItem = response.data.transactions.map((x) => {
            const transaction = new TransactionHistoryViewModel(x);
            return transaction;
          });
          this.transactionViewmodel.tranxHistory = [...oldItem.concat(newItem)];
          this.loading = false;
        }
        else {
          this.loading = false;
        }

      }, () => {
        this.loading = false;
      }));
      }, 5000);

    }
  }

  ngOnDestroy() {
    this.transactionSubscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
