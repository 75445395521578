<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="inviteForm">
      <div class="card-body invite-link-content">
        <div class="form-group">
          <label for="invite-message" class="invitation-text">Invitation Text</label>
          <textarea #invite class="input-disabled" id="invite-message" rows="6" formControlName="inviteMessage"></textarea>
        </div>
        <div class="copy-btn-container">
          <button (click)="copyToClip(invite)" class="btn-copy">Copy text</button>
        </div>
      </div>
    </form>
  </div>
</div>