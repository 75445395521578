import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ProfileService } from '../profile/services/profile.service';
import { Carpark } from '../shared/models/carpark';
import { RegisteredVehicle } from '../shared/models/registered-vehicle';
import { Response } from '../shared/models/response';
import { DialogService } from '../shared/services/dialog.service';
import { ToastService } from '../shared/services/toast.service';
import { AddVehicleModalComponent } from '../shared/widgets/add-vehicle-modal/add-vehicle-modal.component';
import { CarparkRatesComponent } from '../shared/widgets/carpark-rates/carpark-rates.component';
import { VerifyReservation } from './models/verify-reservation';
import { ReservationService } from './services/reservation.service';
import { ReservationPaymentSummaryComponent } from './widget/reservation-payment-summary/reservation-payment-summary.component';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit, OnDestroy {

  carparkInfo: Carpark;
  minDate: Date;
  registeredVehicles: RegisteredVehicle[] = [];
  private subscriptions: Subscription[] = [];

  reservationForm = new FormGroup({
    reservationDate: new FormControl('', [Validators.required, this.reservationDateValidator()]),
    reservationPlate: new FormControl('', [Validators.required]),
    reservationCheckIn: new FormControl('', [Validators.required]),
    reservationCheckOut: new FormControl('', [Validators.required])
  }, [this.checkInTimeValidatorNew(), this.checkOutTimeValidatorNew()]);

  constructor(private router: Router,
              private dialogService: DialogService,
              private profileService: ProfileService,
              private reservationService: ReservationService,
              private toastService: ToastService) {
    const state = this.router.getCurrentNavigation().extras.state;

    if (state) {
      const fromMap = state.fromMap;

      if (fromMap) {
        this.carparkInfo = state.carparkInfo;
      }
    } else {
      this.router.navigateByUrl('user/home');
      return;
    }
  }

  ngOnInit() {
    this.minDate = new Date();
    this.getRegisteredVehicle();
  }

  onAddVehicle() {
    const config: ModalOptions = { class: 'modal-dialog-centered add-vehicle', backdrop: 'static' };

    this.dialogService.show(AddVehicleModalComponent, config);

    const sub = this.dialogService.modalSubjectAsObservable()
    .subscribe((addedStatus: boolean) => {
      sub.unsubscribe();
      if (addedStatus === true) {
        this.getRegisteredVehicle();
      }
    });
  }

  getRegisteredVehicle() {
    this.subscriptions.push(this.profileService.getRegisteredVehicle()
    .subscribe((response: Response<{lookupUserVehicles: RegisteredVehicle[]}>) => {
      if (response .meta.metaData.statusCode === 200) {
        this.registeredVehicles = response.data.lookupUserVehicles.filter(x => x.active === true);
      } else {
      }
    }, () => {

    }));
  }

  reservationDateValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let isValid = true;

      const dateInput = control.value;
      let dateToMill: number;

      dateToMill = Date.parse(dateInput);

      if (isNaN(dateToMill)) {
        isValid = false;
      }

      return isValid ? null : {reservationDate: {value: control.value, error: 'reservation date is invalid'}};
    };
  }

  checkInTimeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

      const checkInTimeCtl = control.value;

      const checkInTimeMill = Date.parse(checkInTimeCtl);

      if (isNaN(checkInTimeMill)) {
        return {reservationCheckIn: {value: control.value, error: 'check in time is invalid'}};
      }

      if (new Date(checkInTimeCtl) < new Date()) {
        return {reservationCheckIn: {value: control.value, error: 'check in time is invalid'}};
      }

      return null;
    };
  }

  checkInTimeValidatorNew(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

      const checkInTimeCtl = control.get('reservationCheckIn').value as string;
      const reservationDateCtl = control.get('reservationDate').value;

      const reservationDateInmill = Date.parse(reservationDateCtl);

      let currentDate = new Date();

      if (!isNaN(reservationDateInmill) ) {
        currentDate = new Date(reservationDateInmill);
      }

      const selectedDate = this.convertToDate(checkInTimeCtl, currentDate);

      if (selectedDate.getDate() !== currentDate.getDate()) {
        return {reservationCheckIn: {value: control.value, error: 'check in time is invalid'}};
      }

      if (new Date().getDate() === selectedDate.getDate() &&  selectedDate < currentDate) {
        return {reservationCheckIn: {value: control.value, error: 'check in time is invalid'}};
      }

      return null;
    };
  }

  checkOutTimeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

      const checkInTimeCtl = control.get('reservationCheckIn').value;
      const checkOutTimeCtl = control.get('reservationCheckOut').value;

      const checkInTimeMill = Date.parse(checkInTimeCtl);
      const checkOutTimeMill = Date.parse(checkOutTimeCtl);

      if (isNaN(checkOutTimeMill)) {
        return {reservationCheckOut: {value: control.value, error: 'check out time is invalid'}};
      }else if (isNaN(checkInTimeMill)) {
        return null;
      }else if (checkOutTimeMill <= checkInTimeMill) {
        return {reservationCheckOut: {value: control.value, error: 'check out time is invalid'}};
      }

      return null;
    };
  }

  checkOutTimeValidatorNew(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {

      const checkInTimeCtl = control.get('reservationCheckIn').value as string;
      const checkOutTimeCtl = control.get('reservationCheckOut').value as string;
      const reservationDateCtl = control.get('reservationDate').value;

      const reservationDateInmill = Date.parse(reservationDateCtl);

      let currentDate = new Date();

      if (!isNaN(reservationDateInmill) ) {
        currentDate = new Date(reservationDateInmill);
      }

      const selectedCheckOutDate = this.convertToDate(checkOutTimeCtl, currentDate);

      if (selectedCheckOutDate.getDate() !== currentDate.getDate()) {
        return {reservationCheckOut: {value: control.value, error: 'check out time is invalid'}};
      }

      if (new Date().getDate() === selectedCheckOutDate.getDate() && selectedCheckOutDate < currentDate) {
        return {reservationCheckOut: {value: control.value, error: 'check out time is invalid'}};
      }

      if (checkInTimeCtl === '') {
        return null;
      }

      const checkInDate = this.convertToDate(checkInTimeCtl, currentDate);

      if (selectedCheckOutDate <= checkInDate) {
        return {reservationCheckOut: {value: control.value, error: 'check out time is invalid'}};
      }

      return null;
    };
  }

  hasRates() {
    return this.carparkInfo.feeType === 'Custom';
  }

  showRate() {

    const initialState: any = {
      parkingRates: this.carparkInfo.parkingRates
    };

    const config: ModalOptions = { class: 'modal-dialog-centered add-vehicle', backdrop: 'static', initialState };
    this.dialogService.show(CarparkRatesComponent, config);

    const sub = this.dialogService.modalSubjectAsObservable()
    .subscribe(res => {
      sub.unsubscribe();
    });
  }

  onReserve() {
    const checkInTime: Date = this.reservationForm.get('reservationCheckIn').value;
    const checkOutTime: Date = this.reservationForm.get('reservationCheckOut').value;

    const checkInTimeTo12Hr = this.convertTimeTo12Hr(checkInTime);
    const checkOutTimeTo12Hr = this.convertTimeTo12Hr(checkOutTime);

    const reservationPeriod = checkInTimeTo12Hr + ' - ' + checkOutTimeTo12Hr;

    const selectedReservationDate: Date = new Date(this.reservationForm.get('reservationDate').value);
    const day = selectedReservationDate.getDate();
    const year = selectedReservationDate.getFullYear();
    const month = selectedReservationDate.getMonth();

    const reservationDate = `${day.toString().padStart(2, '0')}-${(month + 1).toString().padStart(2, '0')}-${year}`;



    this.subscriptions.push(this.reservationService
      .verifyReservation(this.carparkInfo.carpark_id , reservationDate, this.reservationForm.get('reservationPlate').value,
      reservationPeriod)
    .subscribe((response: Response<VerifyReservation>) => {
        if (response.meta.metaData.statusCode === 200) {

        const initialState: any = {
          data: response.data,
          reservationInfo: {
            carparkId: this.carparkInfo.carpark_id,
            carparkName: this.carparkInfo.carpark_name,
            plateNumber: this.reservationForm.get('reservationPlate').value,
            reservationDate,
            reservationPeriod
          }
        };


        const config: ModalOptions = { class: 'modal-dialog-centered referral', backdrop: 'static', initialState };
        this.dialogService.show(ReservationPaymentSummaryComponent, config);

        const sub = this.dialogService.modalSubjectAsObservable()
                    .subscribe((status: string) => {
                      sub.unsubscribe();
                      if (status === 'successful') {
                        this.toastService.showSuccess('Reservation successful', 'Success');
                        this.router.navigateByUrl('user/home');
                      }
                    });

      } else if (response.error && response.error.error_msg) {
        this.toastService.showError(response.error.error_msg.title);
      } else {
        this.toastService.showError('An error occured during reservation');
      }
    }));
  }

  onReserveNew() {

    let checkInTime = this.reservationForm.get('reservationCheckIn').value as string;
    checkInTime = checkInTime.padStart(8, '0');

    let checkOutTime = this.reservationForm.get('reservationCheckOut').value as string;
    checkOutTime = checkOutTime.padStart(8, '0');

    const reservationPeriod = checkInTime + ' - ' + checkOutTime;

    const selectedReservationDate: Date = new Date(this.reservationForm.get('reservationDate').value);
    const day = selectedReservationDate.getDate();
    const year = selectedReservationDate.getFullYear();
    const month = selectedReservationDate.getMonth();

    const reservationDate = `${day.toString().padStart(2, '0')}-${(month + 1).toString().padStart(2, '0')}-${year}`;



    this.subscriptions.push(this.reservationService
      .verifyReservation(this.carparkInfo.carpark_id , reservationDate, this.reservationForm.get('reservationPlate').value,
      reservationPeriod)
    .subscribe((response: Response<VerifyReservation>) => {
        if (response.meta.metaData.statusCode === 200) {

        const initialState: any = {
          data: response.data,
          reservationInfo: {
            carparkId: this.carparkInfo.carpark_id,
            carparkName: this.carparkInfo.carpark_name,
            plateNumber: this.reservationForm.get('reservationPlate').value,
            reservationDate,
            reservationPeriod
          }
        };


        const config: ModalOptions = { class: 'modal-dialog-centered referral', backdrop: 'static', initialState };
        this.dialogService.show(ReservationPaymentSummaryComponent, config);

        const sub = this.dialogService.modalSubjectAsObservable()
                    .subscribe((status: string) => {
                      sub.unsubscribe();
                      if (status === 'successful') {
                        this.toastService.showSuccess('Reservation successful', 'Success');
                        this.router.navigateByUrl('user/home');
                      }
                    });

      } else if (response.error && response.error.error_msg) {
        this.toastService.showError(response.error.error_msg.title);
      } else {
        this.toastService.showError('An error occured during reservation');
      }
    }));
  }

  convertTimeTo12Hr(date: Date) {
    date = new Date(date);
    let hours = date.getHours() ; // gives the value in 24 hours format
    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
    const minutes = date.getMinutes();

    const hoursToString = hours.toString().padStart(2, '0');
    const minutesToString = minutes.toString().padStart(2, '0');

    return hoursToString + ':' + minutesToString + ' ' + AmOrPm;
  }

  convertToDate(twelveHrTime: string, currentDate: Date): Date {
      const timePart = twelveHrTime.split(' ');
      const AmOrPm = timePart[1];
      const hrMinsPart = timePart[0].split(':');
      const hr = hrMinsPart[0];
      const mins = hrMinsPart[1];

      let selectedDate;

      if (AmOrPm === 'PM' && +hr === 12) {
        selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(),
                                currentDate.getDate(), +hr, +mins, currentDate.getSeconds(),
                                currentDate.getMilliseconds());
      }
      else if (AmOrPm === 'AM' && +hr === 12) {
        selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(),
                                currentDate.getDate(), +hr + 12, +mins, currentDate.getSeconds(),
                                currentDate.getMilliseconds());
      }
      else if (AmOrPm === 'PM') {
        selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(),
                                currentDate.getDate(), +hr + 12, +mins, currentDate.getSeconds(),
                                currentDate.getMilliseconds());
      }
      else {
        selectedDate = new Date(currentDate.getFullYear(), currentDate.getMonth(),
        currentDate.getDate(), +hr, +mins, currentDate.getSeconds(),
        currentDate.getMilliseconds());
      }

      return selectedDate;

  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
