import { Injectable, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

private modalSubject = new Subject<any>();

constructor(private modalService: BsModalService) { }

show(content: string | TemplateRef<any> | (new (...args: any[]) => any), config?: ModalOptions<any>): BsModalRef<any> {
  return this.modalService.show(content, config);
}

modalSubjectAsObservable() {
  return this.modalSubject.asObservable();
}

getModalSubject() {
  return this.modalSubject;
}

}
