<div class="profile-page">
  <app-nav-bar [hasBkgColor]="true"></app-nav-bar>
  <div class="profile-container" *ngIf="profileVm">
    <div class="profile-content">
      <div class="profile-info">
        <div class="user-info card">
          <div class="card-body">
            <div class="qrcode-container" *ngIf="showQRCode()">
              <app-qr-code [value]="getQRCodeValue()" [cssClass]="'profile-qrcode'"></app-qr-code>
            </div>

            <div class="profile-info_user-info">
              <div class="profile-info_user-info__item">
                <div class="profile-info_user-info_header">
                  Name
                </div>
                <div class="profile-info_user-detail">
                  {{profileVm.name}}
                </div>
              </div>
              <div  class="profile-info_user-info__item">
                <div class="profile-info_user-info_header">
                  Phone Number
                </div>
                <div class="profile-info_user-detail">
                  {{profileVm.phoneNumber}}
                </div>
              </div>
              <div  class="profile-info_user-info__item">
                <div class="profile-info_user-info_header">
                  Email Address
                </div>
                <div class="profile-info_user-detail">
                  {{profileVm.email}}
                </div>
              </div>
              <div class="divider"></div>
              <div class="usage-container">
                <div class="date-joined">
                  {{getUserJoinedText()}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="user-vehicles card">
          <div class="card-body">
            <div class="vehicles-content" *ngIf="registeredVehicles.length > 0">
              <ng-container  *ngFor="let registeredVehicle of registeredVehicles">
                <ng-container [ngSwitch]="registeredVehicle.category">
                  <ng-container *ngSwitchCase="'Saloon car'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons"
                      (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_sedan_car.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Motor Bike'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons" (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_bike.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Bus'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons" (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_bus_side.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Sport Utility Vehicle'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons" (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_suv.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Pick up'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons" (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_pickup.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Truck'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons" (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_truck.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="'Others'">
                    <div class="vehicles-item">
                      <span title="remove vehicle" class="remove-vehicle material-icons" (click)=onRemoveVehicle(registeredVehicle.plateNumber)>
                        indeterminate_check_box
                        </span>
                      <img src="../../assets/img/vehicle_others.png" alt="" width="80">
                      <p style="font-weight: 600;">{{registeredVehicle.plateNumber}}</p>
                      <p>{{registeredVehicle.category}}</p>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </div>
            <div class="no-vehicle-text" *ngIf="registeredVehicles.length === 0">
              <p >You don't have a registered vehicle</p>
            </div>
            <div class="add-vehicle-btn-container">
              <button class="add-vehicle-btn" (click)="onAddVehicle()">Add Vehicle</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>