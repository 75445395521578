import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SessionService } from './services/session.service';
import { AuthenticationService } from './services/authentication.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

constructor(private router: Router,
            private sessionService: SessionService,
            private authService: AuthenticationService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const usercontext = this.sessionService.getUserContext();
    const token = this.sessionService.getAccessToken();

    const url = this.router.url;

    if (url.startsWith('/user')) {

      if (!usercontext || !token) {
        this.authService.logoutUser();
        return;
      }

      req =  req.clone({
        setHeaders: {
          token
        }
      });

      // req.headers.delete('require-token');
    }

    return next.handle(req).pipe(tap((event) => {
      if (event instanceof HttpResponse) {

        if (event.body && event.body.error && event.body.error.error_msg &&
           event.body.error.error_msg.title === 'Invalid authorization token') {
          this.authService.logoutUser();
        }
      }
      return event;
    }), catchError(err => {
      if (err.status === 401) {
        this.authService.logoutUser();
      }

      return throwError(err);
    }));
  }

}
