import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-web-referral-nav-bar',
  templateUrl: './web-referral-nav-bar.component.html',
  styleUrls: ['./web-referral-nav-bar.component.scss']
})
export class WebReferralNavBarComponent implements OnInit {

  @Input() hasBkgColor: boolean;

  userInitials = '';

  constructor(private sessionService: SessionService,
              private authenticationService: AuthenticationService) { }

  ngOnInit() {
  }

  logOut() {
    this.authenticationService.logoutUser();
  }

}
