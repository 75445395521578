import { Injectable } from '@angular/core';
import { AppConstants } from '../models/app-constants';
import { UserContext } from '../models/user-context';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private userContext: UserContext;

constructor() { }

setUserContext(userContext: UserContext){

  this.userContext = userContext;

  const userContextTostring = JSON.stringify(this.userContext);
  window.localStorage.setItem(AppConstants.PWCUSERCONTEXTKEY, userContextTostring);
}

getUserContext() {

  if (this.userContext) {
    return this.userContext;
  }

  const userContextTostring = window.localStorage.getItem(AppConstants.PWCUSERCONTEXTKEY);
  if (userContextTostring) {
    this.userContext = JSON.parse(userContextTostring);
    return this.userContext;
  }
}

getAccessToken(){
  return window.localStorage.getItem(AppConstants.PWCTOKENKEY);
}

setAccessToken(token: string){
  window.localStorage.setItem(AppConstants.PWCTOKENKEY, token);
}

removeToken(){
  window.localStorage.removeItem(AppConstants.PWCTOKENKEY);
}

removeUserContext(){
  window.localStorage.removeItem(AppConstants.PWCUSERCONTEXTKEY);
}

clearSession(){
  this.removeToken();
  this.removeUserContext();
}

public isTokenExpired(token: string): boolean {
  try {
    if (decode<any>(token).exp < Date.now() / 1000) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return true;
  }
}

}
