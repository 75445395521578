import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WebReferralService {

  private BASE_URL = environment.baseUrl;

constructor(private http: HttpClient) { }

getReferralClaim(refId: string) {
  return this.http.post(this.BASE_URL,
    {
      query: '{ referralClaiming {firstName lastName total} }',
      variables: {
          refId
      }
  });
 }
}
