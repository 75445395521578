<div>
  <app-nav-bar [hasBkgColor]="true"></app-nav-bar>
  <div class="parking-history-container">
    <div class="parking-history-content">
      <div class="search-results" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()" [scrollWindow]="false">
        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <!-- <th scope="col"><span style="vertical-align:bottom;" class="material-icons">done</span></th> -->
                <th scope="col">Carpark</th>
                <th scope="col">Status</th>
                <th scope="col">Amount</th>
                <th class="hide-mobile" scope="col">Payment method</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let history of parkingHistoryViewModel">
                <tr class="table-pointer" (click)="onHistoryClicked(history)">
                  <td>{{history.carparkName}}</td>
                  <td>{{history.parkingStatus}}</td>
                  <td>{{history.getAmountText()}}</td>
                  <td class="hide-mobile">In-App Payment</td>
                  <td>{{history.reservationDate}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <!-- <ng-container *ngFor="let history of parkingHistoryViewModel">
          <div class="card">
            <div class="card-body">
              
            </div>
          </div>
        </ng-container> -->
        
      </div>
    </div>
  </div> 
  <div *ngIf="loading" style="font-size:10px;" class="text-center">
    <i class="fa fa-circle-notch fa-spin mr-2"></i>Loading...
  </div>
</div>
