import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { ProfileVm } from '../shared/models/profile-vm';
import { RegisteredVehicle } from '../shared/models/registered-vehicle';
import { Response } from '../shared/models/response';
import { User } from '../shared/models/user';
import { DialogService } from '../shared/services/dialog.service';
import { AddVehicleModalComponent } from '../shared/widgets/add-vehicle-modal/add-vehicle-modal.component';
import { RemoveVehicleComponent } from '../shared/widgets/remove-vehicle/remove-vehicle.component';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  profileVm: ProfileVm;
  registeredVehicles: RegisteredVehicle[] = [];
  passCode: number;
  private subscriptions: Subscription[] = [];

  constructor(private dialogService: DialogService,
              private profileService: ProfileService) { }

  ngOnInit() {
    this.subscriptions.push(this.profileService.getUserProfile().subscribe((response: Response<{lookupUser: User}>) => {
      if (response.meta.metaData.statusCode === 200) {
        this.profileVm = new ProfileVm(response.data.lookupUser);
      }
    }));

    this.getRegisteredVehicle();

    this.subscriptions.push(this.profileService.getPassCode()
              .subscribe((passCode: Response<{generatePasscode: number}>) => {
                if (passCode.meta.metaData.statusCode === 200 ) {
                  this.passCode = passCode.data.generatePasscode;
                } else {
                }
              }, () => {
              }));
  }

  onAddVehicle() {
    const config: ModalOptions = { class: 'modal-dialog-centered add-vehicle', backdrop: 'static' };
    this.dialogService.show(AddVehicleModalComponent, config);

    const sub = this.dialogService.modalSubjectAsObservable()
    .subscribe((addedStatus: boolean) => {
      sub.unsubscribe();
      if (addedStatus === true) {
        this.getRegisteredVehicle();
      }
    });
  }

  onRemoveVehicle(plateNumber: string) {
    const category = this.registeredVehicles.find(x => x.plateNumber === plateNumber).category;
    const initialState: any = {
      category,
      plateNumber
    };

    const config: ModalOptions = { class: 'modal-dialog-centered remove-vehicle', backdrop: 'static', initialState };

    this.dialogService.show(RemoveVehicleComponent, config);

    const sub = this.dialogService.modalSubjectAsObservable()
    .subscribe((removeStatus: boolean) => {
      sub.unsubscribe();
      if (removeStatus === true) {
        this.getRegisteredVehicle();
      }
    });
  }

  getRegisteredVehicle() {
    this.subscriptions.push(this.profileService.getRegisteredVehicle()
    .subscribe((response: Response<{lookupUserVehicles: RegisteredVehicle[]}>) => {
      if (response .meta.metaData.statusCode === 200) {
        this.registeredVehicles = response.data.lookupUserVehicles.filter(x => x.active === true);
      } else {
      }
    }, () => {

    }));
  }

  getQRCodeValue() {
    // return `${this.profileVm.accessId} | ${this.profileVm.userId} | ${this.profileVm.name}`;
    return `${this.profileVm.accessId} | ${btoa(this.passCode.toString())}`;
  }

  getUserJoinedText() {

    const dateText = this.profileVm.dateJoined.split(',')[1].split('-')[0].trim();
    const datePart = dateText.split('/');

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
                        'July', 'August', 'September', 'October', 'November', 'December'];

    const date = new Date(`${datePart[1]}/${datePart[0]}/${datePart[2]}`);
    return `Member since ${monthNames[date.getMonth()]}, ${date.getFullYear()}`;
  }

  showQRCode() {
    return this.registeredVehicles.some(r => r.active);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

}
