import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserContext } from 'src/app/shared/models/user-context';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { SessionService } from 'src/app/shared/services/session.service';
import { TransactionService } from '../services/transaction.service';

@Component({
  selector: 'app-payment-collection-modal',
  templateUrl: './payment-collection-modal.component.html',
  styleUrls: ['./payment-collection-modal.component.scss']
})
export class PaymentCollectionModalComponent implements OnInit {

  usercontext: UserContext;
  paymentCollectionForm = new FormGroup({
    firstName: new FormControl({value: '', disabled: true}, [Validators.required]),
    lastName: new FormControl({value: '', disabled: true}, [Validators.required]),
    email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.email]),
    amount: new FormControl('', [Validators.required, this.topUpAmountValidator()])
  });
  paymentGatewayPercentage: number;
  paymentGatewayTransaction: number;
  transactionFeePercentage: number;
  firstName: string;
  lastName: string;
  email: string;

  constructor(private transactionService: TransactionService,
              public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.paymentCollectionForm.patchValue({firstName: this.firstName});
    this.paymentCollectionForm.patchValue({lastName: this.lastName});
    this.paymentCollectionForm.patchValue({email: this.email});
  }

  onContinue() {
    this.transactionService.getPaymentSubject().next({
      firstName: this.paymentCollectionForm.get('firstName').value,
      lastName: this.paymentCollectionForm.get('lastName').value,
      email: this.paymentCollectionForm.get('email').value,
      amount: this.paymentCollectionForm.get('amount').value,
      total: this.getTotal()
    });
    this.bsModalRef.hide();
  }

  getTransactionFee() {

    const amount = +this.paymentCollectionForm.get('amount').value;

    if (isNaN(amount)) {
      return 0;
    }

    const parchiveCharges = (this.transactionFeePercentage * amount) / 100; // parchiveTransactPercentage.div(100F).times(amount)

    let transactionFee: number;

    if (amount >= 2500) {
      transactionFee = (((this.paymentGatewayPercentage * amount) / 100) +
      this.paymentGatewayTransaction) + ((this.transactionFeePercentage * amount) / 100);
    } else {
      transactionFee = ((this.paymentGatewayPercentage * amount) / 100) + ((this.transactionFeePercentage * amount) / 100);
    }

    if ((transactionFee - parchiveCharges) > (2000 - parchiveCharges)) {
        transactionFee = 2000 + parchiveCharges;
    }

    return parseFloat(transactionFee.toFixed(2));
}


public get transactionFeeText(): string {

  return new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(this.getTransactionFee());
}

public getTotal() {
  const amount = +this.paymentCollectionForm.get('amount').value;

  if (isNaN(amount)) {
    return 0;
  }
  const total = this.getTransactionFee() + parseFloat(amount.toFixed(2));
  return total;
}

public get totalFeeText(): string {

  return new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(this.getTotal());
}

public get amountText(): string {

  return new Intl.NumberFormat('en-Ng', { style: 'currency', currency: 'NGN' }).format(this.paymentCollectionForm.get('amount').value);
}

topUpAmountValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    let isValid = true;

    const amount = control.value;

    const amountAsString = amount as string;
    const decimalIndex = amountAsString.indexOf('.');

    if (isNaN(amount)) {
      isValid = false;
    }
    else if (+amount < 500) {
      isValid = false;
    }
    else if ( decimalIndex !== -1 ) {
      if (amountAsString.substring(decimalIndex + 1).length > 2) {
        isValid = false;
      }
    }
    // (amount as string).substr((amount as string).indexOf('.') + 1).length > 2

    return isValid ? null : {amount: {value: control.value, error: 'Top up amount is invalid'}};
  };
}


}
