<div>
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="addVehicleCollectionForm" (ngSubmit)="onAddVehicle()">
      <div class="card-body add-vehicle-content">
        <div class="form-header">
          <h5>Enter vehicle infomation</h5>
        </div>

        <div class="form-group">
          <label for="vehicle-category">Select Vehicle Category</label>
          <select id="vehicle-category" formControlName="vehicleCategory">
            <option value="">Select vehicle category</option>
            <option value="Saloon car">Saloon car</option>
            <option value="Motor Bike">Motor Bike</option>
            <option value="Bus">Bus</option>
            <option value="Sport Utility Vehicle">Sport Utility Vehicle</option>
            <option value="Pick up">Pick up</option>
            <option value="Truck">Truck</option>
            <option value="Others">Others</option>
          </select>
        </div>

        <div class="form-group">
          <label for="vehicle-plate-number">Vehicle Plate number</label>
          <input type="text" id="vehicle-plate-number" placeholder="Enter vehicle plate number" formControlName="vehiclePlateNumber">
          <div *ngIf="addVehicleCollectionForm.get('vehiclePlateNumber').invalid && 
                                                        (addVehicleCollectionForm.get('vehiclePlateNumber').dirty || 
                                                        addVehicleCollectionForm.get('vehiclePlateNumber').touched)"
                                                class="error">
            <div *ngIf="addVehicleCollectionForm.get('vehiclePlateNumber').errors.pattern">
              Enter a valid plate number.
            </div>         
          </div>
        </div>
        
        <div>
          <button 
          [disabled]="addVehicleCollectionForm.invalid"
          class="btn-add-vehicle"
          [ngClass]="{'btn-disabled': addVehicleCollectionForm.invalid}"
          type="submit">
          Submit
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
